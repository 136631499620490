import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useIsAuthenticated } from "state/hooks";

interface Props extends RouteProps {
  loginUrl?: string;
}

const PrivateRoute = ({
  loginUrl = "/login",
  children,
  ...restProps
}: Props) => {
  const isAuthenticated = useIsAuthenticated();
  return (
    <Route {...restProps}>
      {isAuthenticated ? (
        children
      ) : (
        <Redirect
          to={{ pathname: loginUrl, state: { from: restProps.location } }}
        />
      )}
    </Route>
  );
};

export default PrivateRoute;
