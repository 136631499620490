import React from "react";
import { Form, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import clsx from "clsx";
import { required } from "./validators";
import styles from "./index.module.scss";

interface Props {
  active: boolean;
}

interface Values {
  email: string;
  password: string;
}

const SignUpForm = ({ active }: Props) => {
  const submit = (values: Values) => {
    return { [FORM_ERROR]: "注册功能未激活" };
  };

  return (
    <Form<Values> onSubmit={submit}>
      {({ handleSubmit, hasValidationErrors, submitError }) => (
        <form
          className={clsx(styles["tab-content"], active && styles.active)}
          onSubmit={handleSubmit}
        >
          <label>
            邮箱:
            <Field
              name="email"
              component="input"
              type="email"
              validate={required}
            />
          </label>

          <label>
            密码:
            <Field
              name="password"
              component="input"
              type="password"
              validate={required}
            />
          </label>

          <label>
            确认密码:
            <Field
              name="confirmPassword"
              component="input"
              type="password"
              validate={required}
            />
          </label>

          <label>
            注册码:
            <Field
              name="code"
              component="input"
              type="password"
              validate={required}
            />
          </label>

          <div className={styles.err}>{submitError}</div>
          <button
            className={styles["primary-button"]}
            type="submit"
            disabled={hasValidationErrors}
          >
            注册
          </button>
        </form>
      )}
    </Form>
  );
};

export default SignUpForm;
