import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { tokenState } from "state";
import * as api from "api";
import Loading from "components/Loading";
import EditJob from "./EditJob";

const Page = () => {
  const token = useRecoilValue(tokenState);
  const history = useHistory();

  const { jobId } = useParams<{ jobId: string }>();
  const [job, setJob] = useState<JobWithStats>();

  const saveJob = async () => {
    if (!job) return;

    const { data, error } = await api.updateJob(token)(job);
    if (data) {
      alert("保存成功!");
    }
    if (error) {
      alert(`保存失败!\n错误信息: ${error}`);
    }
  };

  const deleteJob = async () => {
    if (!job) return;

    if (window.confirm(`确认删除职位${job?.name}?`)) {
      const { data, error } = await api.deleteJob(token)(
        job.company._id,
        job._id
      );
      if (data) {
        alert("删除成功!");
        history.goBack();
      }
      if (error) {
        alert(`删除失败!\n错误信息: ${error}`);
      }
    }
  };

  const toggleHighlight = async () => {
    if (!job) return;

    const { data, error } = await api.setJobHighlight(token)(
      job._id,
      !job.highlight
    );

    if (data) {
      setJob({ ...job, highlight: !job.highlight });
    }

    if (error) {
      alert(`设置精选失败!\n错误信息: ${error}`);
    }
  };

  const uploadImage = async (
    type: "image" | "wechatShareImage",
    files: FileList
  ) => {
    if (!job || !files.length) return;

    if (files[0].size > 300 * 1024) {
      const error = "文件过大! 请勿超过300KB.";
      alert(`上传图片失败!\n错误信息: ${error}`);
      return;
    }

    const { data, error } = await api.uploadJobImage(token)(
      job._id,
      type,
      files[0]
    );

    if (data) {
      setJob({ ...job, [type]: data[type] });
    }

    if (error) {
      alert(`上传图片失败!\n错误信息: ${error}`);
    }
  };

  const navToApplicants = () => {
    if (!job) return;

    history.push(`/user-list/${job._id}`);
  };

  useEffect(() => {
    const loadJob = async () => {
      const { data, error } = await api.getJob(token)(jobId);

      if (data) {
        setJob({
          image: "",
          wechatShareImage: "",
          contactDisplay: 0,
          ...data.job,
        } as JobWithStats);
      }

      if (error) {
        console.log(error);
      }
    };
    loadJob();
  }, [jobId, token]);

  return !job ? (
    <Loading />
  ) : (
    <EditJob
      job={job}
      onChange={setJob}
      onSave={saveJob}
      onDelete={deleteJob}
      onToggleHighlight={toggleHighlight}
      onUploadImage={uploadImage}
      onViewApplicants={navToApplicants}
    />
  );
};

export default Page;
