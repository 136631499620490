import React, { useState } from "react";
import {
  FaChevronLeft,
  FaSearch,
  FaPencilAlt,
  FaSave,
  FaTimes,
} from "react-icons/fa";
import { useHistory } from "react-router";
import styles from "./NavBar.module.scss";

interface Props {
  isEditingTags: boolean;
  editDisabled: boolean;
  batchEditDisabled: boolean;
  saveDisabled: boolean;
  onSearch: (name: string) => void;
  onEdit: () => void;
  onSave: () => void;
  onCancel: () => void;
  onBatchEdit: () => void;
}

const NavBar = ({
  isEditingTags,
  editDisabled,
  batchEditDisabled,
  saveDisabled,
  onSearch,
  onEdit,
  onSave,
  onCancel,
  onBatchEdit,
}: Props) => {
  const history = useHistory();
  const [searchText, setSearchText] = useState("");

  return (
    <div className={styles.navbar}>
      <div className={styles.group}>
        <button
          className={styles["light-button"]}
          onClick={() => history.goBack()}
        >
          <FaChevronLeft />
          &nbsp;返回
        </button>
      </div>
      <div className={styles.group}>
        {!isEditingTags && (
          <>
            <input
              type="search"
              placeholder="输入申请人姓名"
              onKeyPress={(e) => {
                if (e.code === "Enter") {
                  onSearch(searchText.trim());
                }
              }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <button
              className={styles["icon-only-button"]}
              onClick={() => onSearch(searchText.trim())}
            >
              <FaSearch />
            </button>
          </>
        )}

        {!isEditingTags ? (
          <button
            className={styles["light-button"]}
            onClick={onEdit}
            disabled={editDisabled}
          >
            <FaPencilAlt />
            &nbsp;编辑标签
          </button>
        ) : (
          <>
            <button
              className={styles["light-button"]}
              onClick={onBatchEdit}
              disabled={batchEditDisabled}
            >
              <FaPencilAlt />
              &nbsp;批量修改
            </button>
            <button
              className={styles["light-button"]}
              onClick={onSave}
              disabled={saveDisabled}
            >
              <FaSave />
              &nbsp;保存标签
            </button>
            <button className={styles["light-button"]} onClick={onCancel}>
              <FaTimes />
              &nbsp;放弃修改
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default NavBar;
