import React from "react";
import { Link } from "react-router-dom";
import { formatDate } from "utils/format";
import styles from "./JobList.module.scss";

interface Props {
  jobs: Array<Job & { deadline?: string }>;
}

const JobList = ({ jobs }: Props) => {
  return (
    <table className={styles.jobs}>
      <thead>
        <tr>
          <th>ID</th>
          <th>名称</th>
          <th>截止日期</th>
          <th>操作</th>
        </tr>
      </thead>
      <tbody>
        {jobs.map((job) => (
          <tr key={job._id}>
            <td>{job._id}</td>
            <td>{job.name}</td>
            <td>{formatDate(job.deadline)}</td>
            <td>
              <Link to={`/edit-job/${job._id}`}>查看详细 </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default JobList;
