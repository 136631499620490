import { useRecoilValue } from "recoil";
import { isAuthenticatedState, tokenState } from "state";

export const useToken = () => {
  const token = useRecoilValue(tokenState);

  return token;
};

export const useIsAuthenticated = () => {
  const isAuthenticated = useRecoilValue(isAuthenticatedState);

  return isAuthenticated;
};
