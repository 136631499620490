import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  allTagsState,
  extraUserInfoByIdState,
  isEditingTagsState,
  newTagsByIdState,
  selectedUserIdsState,
  tagsByIdState,
  usersState,
} from "state";
import UserRow from "./UserRow";
import styles from "./UserList.module.scss";

interface Props {
  stateKey: string;
}

const UserList = ({ stateKey }: Props) => {
  const users = useRecoilValue(usersState(stateKey));
  const [selectedUserIds, setSelectedUserIds] = useRecoilState(
    selectedUserIdsState(stateKey)
  );
  const extraUserInfoById = useRecoilValue(extraUserInfoByIdState(stateKey));
  const isEditingTags = useRecoilValue(isEditingTagsState(stateKey));
  const [tagsById, setTagsById] = useRecoilState(tagsByIdState(stateKey));
  const [newTagsById, setNewTagsById] = useRecoilState(
    newTagsByIdState(stateKey)
  );
  const allTags = useRecoilValue(allTagsState(stateKey));

  const indeterminate =
    selectedUserIds.length > 0 && selectedUserIds.length < users.length;

  const isAllChecked = selectedUserIds.length === users.length;

  const toogleCheckAll = () => {
    if (isAllChecked) setSelectedUserIds([]);
    else setSelectedUserIds(users.map(({ _id }) => _id));
  };

  return (
    <div className={styles["user-list"]}>
      {isEditingTags ? (
        <div className={styles["edit-view"]}>
          <div className={styles["user-edit-row"]}>
            <label className={styles["check-column"]}>
              <input
                type="checkbox"
                checked={isAllChecked}
                onChange={() => toogleCheckAll()}
                ref={(input) => {
                  if (input) input.indeterminate = indeterminate;
                }}
              />
            </label>
            <div className={styles["user-column"]}></div>
          </div>
          {users.map((user) => (
            <div className={styles["user-edit-row"]} key={user._id}>
              <label className={styles["check-column"]}>
                <input
                  type="checkbox"
                  checked={selectedUserIds.includes(user._id)}
                  onChange={() => {
                    if (selectedUserIds.includes(user._id)) {
                      setSelectedUserIds(
                        selectedUserIds.filter((id) => id !== user._id)
                      );
                    } else {
                      setSelectedUserIds([...selectedUserIds, user._id]);
                    }
                  }}
                />
              </label>
              <div className={styles["user-column"]}>
                <UserRow
                  user={user}
                  tags={tagsById[user._id]}
                  newTags={newTagsById[user._id]}
                  extraUserInfo={extraUserInfoById[user._id]}
                  editingTags={true}
                  allTags={allTags}
                  onTagsChange={(user, tags) => {
                    console.log(tags, newTagsById);
                    setNewTagsById({ ...newTagsById, [user._id]: tags });
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className={styles["read-view"]}>
          {users.map((user) => (
            <UserRow
              key={user._id}
              user={user}
              tags={tagsById[user._id]}
              newTags={newTagsById[user._id]}
              extraUserInfo={extraUserInfoById[user._id]}
              editingTags={false}
              allTags={allTags}
              onTagsChange={(user, tags) => {
                console.log(tags, newTagsById);
                setNewTagsById({ ...newTagsById, [user._id]: tags });
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default UserList;
