export function isEdited({
  tags,
  newTags,
}: {
  tags?: Array<string>;
  newTags?: Array<string>;
}) {
  return !(typeof newTags === "undefined"
    ? typeof tags === "undefined"
    : newTags.length === tags?.length &&
      JSON.stringify(tags) === JSON.stringify(newTags));
}
