import React from "react";
import LoadingSpinner from "./LoadingSpinner";
import styles from "./Loading.module.scss";

const Loading = () => {
  return (
    <div className={styles.loading}>
      <span className={styles.spinner}>
        <LoadingSpinner />
      </span>
      <div className={styles["loading-text"]}>加载中...</div>
    </div>
  );
};

export default Loading;
