import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { FaInfoCircle } from "react-icons/fa";
import { tokenState } from "state";
import * as api from "api";
import NavBar from "components/NavBar";
import RenameTagModal from "./RenameTagModal";
import styles from "./index.module.scss";

const EditTag = () => {
  const token = useRecoilValue(tokenState);
  const [tags, setTags] = useState<Array<string>>([]);
  const [activeTag, setActiveTag] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const editTag = (tag: string) => {
    setActiveTag(tag);
    setIsModalOpen(true);
  };

  const renameTag = async (tag: string, newTag: string) => {
    const { error } = await api.renameTag(token)(activeTag, newTag);

    if (!error) {
      setTags(tags.map((t) => (t === tag ? newTag : t)));
      setActiveTag("");
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    const loadTags = async () => {
      const { data } = await api.getTags(token)();
      if (data) {
        setTags(data.tags);
      }
    };
    loadTags();
  }, [token]);

  return (
    <div className={styles.page}>
      <NavBar />

      <div className={styles["page-content"]}>
        <p className={styles["help-text"]}>
          <FaInfoCircle />
          &nbsp;单击标签进行重命名
        </p>
        <div className={styles["tag-list"]}>
          {tags.map((tag) => (
            <span
              key={tag}
              className={styles["tag-item"]}
              onClick={() => editTag(tag)}
            >
              <span className={styles["tag-text"]}>{tag}</span>
            </span>
          ))}
        </div>
      </div>
      {isModalOpen && (
        <RenameTagModal
          isOpen={isModalOpen}
          tags={tags}
          activeTag={activeTag}
          onRename={renameTag}
          onCancel={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};

export default EditTag;
