// import axios from 'axios';

// const backendApiUrl = process.env.BACK_END_URL + "/api/admin";

// function sendPost(url, params, config) {
//   return axios.post(backendApiUrl + url, params, config);
// }

// function updateToken(res) {
//   return store.updateToken(res.data.token);
// }

// function authPost(url, params, _config) {
//   let config = Object.assign({ headers: {} }, _config);
//   config.headers.Authorization = "Bearer " + localStorage.getJson("token");
//   return sendPost(url, params, config);
// }

/*
function multerErrorHandler(err) {
  if (err && err.response) {
    alert(`上传失败!\n错误信息: ${err.response.data.err}`);
  }
  throw err;
}
*/

/*
 *
 *
 *
 *
 *
 */

const apiUrl = process.env.REACT_APP_API_URL + "/api/admin";

const getError = async (res: Response) => {
  const resJson: { error?: string; err?: string } = await res.json();

  return resJson.error || resJson.err || res.statusText;
};

export const getUsers =
  (token: string) =>
  async (jobId: string, pageIndex: number, pageSize: number, name: string) => {
    const url = "/users";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const res = await fetch(apiUrl + url, {
      method: "POST",
      headers: token
        ? { ...headers, Authorization: `Bearer ${token}` }
        : headers,
      referrerPolicy: "no-referrer",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
      body: JSON.stringify({ jobId, pageIndex, pageSize, name }),
    });

    if (res.ok) {
      const resJson: { users: Array<UserView> } = await res.json();

      return {
        data: resJson,
      };
    } else {
      return {
        error: "not found",
      };
    }
  };

export const countUsers =
  (token: string) => async (condition: { jobId: string; name?: string }) => {
    const url = "/user-count";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const res = await fetch(apiUrl + url, {
      method: "POST",
      headers: token
        ? { ...headers, Authorization: `Bearer ${token}` }
        : headers,
      referrerPolicy: "no-referrer",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
      body: JSON.stringify(condition),
    });

    if (res.ok) {
      const resJson: { count: number } = await res.json();

      return {
        data: resJson,
      };
    } else {
      return {
        error: "not found",
      };
    }
  };

export const getUser = (token: string) => async (userId: string) => {
  const url = "/user";
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const res = await fetch(apiUrl + url, {
    method: "POST",
    headers: token ? { ...headers, Authorization: `Bearer ${token}` } : headers,
    referrerPolicy: "no-referrer",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
    body: JSON.stringify({ userId }),
  });

  if (res.ok) {
    const resJson: { user: UserView } = await res.json();

    return {
      data: resJson,
    };
  } else {
    return {
      error: "not found",
    };
  }
};

export const getCompanies = (token: string) => async () => {
  const url = "/companies";
  const headers = {
    Accept: "application/json",
  };
  const res = await fetch(apiUrl + url, {
    method: "POST",
    headers: token ? { ...headers, Authorization: `Bearer ${token}` } : headers,
    referrerPolicy: "no-referrer",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
  });

  if (res.ok) {
    const resJson: { companies: Array<CompanyFolded> } = await res.json();

    return {
      data: resJson,
    };
  } else {
    return {
      error: "not found",
    };
  }
};

export const getCompany = (token: string) => async (companyId: string) => {
  const url = "/company";
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const res = await fetch(apiUrl + url, {
    method: "POST",
    headers: token ? { ...headers, Authorization: `Bearer ${token}` } : headers,
    referrerPolicy: "no-referrer",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
    body: JSON.stringify({ companyId }),
  });

  if (res.ok) {
    const resJson: { company: Company } = await res.json();

    return {
      data: resJson,
    };
  } else {
    return {
      error: "not found",
    };
  }
};

export const updateCompany = (token: string) => async (company: Company) => {
  const url = "/update-company";
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const res = await fetch(apiUrl + url, {
    method: "POST",
    headers: token ? { ...headers, Authorization: `Bearer ${token}` } : headers,
    referrerPolicy: "no-referrer",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
    body: JSON.stringify({ company }),
  });

  if (res.ok) {
    const resJson: { message: string } = await res.json();

    return {
      data: resJson,
    };
  } else {
    return {
      error: "not found",
    };
  }
};

export const deleteCompany = (token: string) => async (companyId: string) => {
  const url = "/delete-company";
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const res = await fetch(apiUrl + url, {
    method: "POST",
    headers: token ? { ...headers, Authorization: `Bearer ${token}` } : headers,
    referrerPolicy: "no-referrer",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
    body: JSON.stringify({ companyId }),
  });

  if (res.ok) {
    const resJson: unknown = await res.json();

    return {
      data: resJson,
    };
  } else {
    return {
      error: "not found",
    };
  }
};

export const newCompany = (token: string) => async () => {
  const url = "/new-company";
  const headers = {
    Accept: "application/json",
  };
  const res = await fetch(apiUrl + url, {
    method: "POST",
    headers: token ? { ...headers, Authorization: `Bearer ${token}` } : headers,
    referrerPolicy: "no-referrer",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
  });

  if (res.ok) {
    const resJson: { company: Company } = await res.json();

    return {
      data: resJson,
    };
  } else {
    return {
      error: "not found",
    };
  }
};

export const newJob = (token: string) => async (companyId: string) => {
  const url = "/new-job";
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const res = await fetch(apiUrl + url, {
    method: "POST",
    headers: token ? { ...headers, Authorization: `Bearer ${token}` } : headers,
    referrerPolicy: "no-referrer",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
    body: JSON.stringify({ companyId }),
  });

  if (res.ok) {
    const resJson: { job: Job; message: string } = await res.json();

    return {
      data: resJson,
    };
  } else {
    return {
      error: "not found",
    };
  }
};

export const updateJob = (token: string) => async (job: Job) => {
  const url = "/update-job";
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const res = await fetch(apiUrl + url, {
    method: "POST",
    headers: token ? { ...headers, Authorization: `Bearer ${token}` } : headers,
    referrerPolicy: "no-referrer",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
    body: JSON.stringify({ job }),
  });

  if (res.ok) {
    const resJson: { message: string } = await res.json();

    return {
      data: resJson,
    };
  } else {
    return {
      error: "not found",
    };
  }
};

export const deleteJob =
  (token: string) => async (companyId: string, jobId: string) => {
    const url = "/delete-job";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const res = await fetch(apiUrl + url, {
      method: "POST",
      headers: token
        ? { ...headers, Authorization: `Bearer ${token}` }
        : headers,
      referrerPolicy: "no-referrer",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
      body: JSON.stringify({ companyId, jobId }),
    });

    if (res.ok) {
      const resJson: { message: string } = await res.json();

      return {
        data: resJson,
      };
    } else {
      return {
        error: "not found",
      };
    }
  };

export const getJob = (token: string) => async (jobId: string) => {
  const url = "/job";
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const res = await fetch(apiUrl + url, {
    method: "POST",
    headers: token ? { ...headers, Authorization: `Bearer ${token}` } : headers,
    referrerPolicy: "no-referrer",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
    body: JSON.stringify({ jobId }),
  });

  if (res.ok) {
    const resJson: { job: Job } = await res.json();

    return {
      data: resJson,
    };
  } else {
    const error = await getError(res);
    return { error };
  }
};

export const adminSignIn = async (email: string, password: string) => {
  const url = "/sign-in";
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const res = await fetch(apiUrl + url, {
    method: "POST",
    headers: headers,
    referrerPolicy: "no-referrer",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
    body: JSON.stringify({ email, password }),
  });

  if (res.ok) {
    const resJson: { message: string; token: string } = await res.json();

    return {
      data: resJson,
    };
  } else {
    return {
      error: res.status === 401 ? "账号、密码不匹配" : "unknown error",
    };
  }
};

export const adminSignUp = async (
  email: string,
  password: string,
  code: string
) => {
  const url = "/sign-up";
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const res = await fetch(apiUrl + url, {
    method: "POST",
    headers: headers,
    referrerPolicy: "no-referrer",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
    body: JSON.stringify({ email, password, code }),
  });

  if (res.ok) {
    const resJson: { message: string; token: string } = await res.json();

    return {
      data: resJson,
    };
  } else {
    return {
      error: "not found",
    };
  }
};

export const setJobHighlight =
  (token: string) => async (jobId: string, highlight: boolean) => {
    const url = "/set-highlight";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const res = await fetch(apiUrl + url, {
      method: "POST",
      headers: token
        ? { ...headers, Authorization: `Bearer ${token}` }
        : headers,
      referrerPolicy: "no-referrer",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
      body: JSON.stringify({ jobId, highlight }),
    });

    if (res.ok) {
      const resJson: { message: string } = await res.json();

      return {
        data: resJson,
      };
    } else {
      return {
        error: "not found",
      };
    }
  };

export const uploadImage =
  (token: string) =>
  async (
    target: string,
    id: string,
    key: string,
    images: ArrayLike<string | Blob>
  ) => {
    let data = new FormData();
    data.append("target", target);
    data.append("id", id);
    data.append("key", key);

    Array.from(images).forEach((image) => data.append("images", image));
    // for (let i = 0; i < images.length; i++) {
    //   data.append("images", images[i]);
    // }

    const url = "/upload-image";
    const headers = {
      Accept: "application/json",
      // "Content-Type": 不填
    };
    const res = await fetch(apiUrl + url, {
      method: "POST",
      headers: token
        ? { ...headers, Authorization: `Bearer ${token}` }
        : headers,
      referrerPolicy: "no-referrer",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
      body: data,
    });

    if (res.ok) {
      const resJson: { value: Array<string>; message: string } =
        await res.json();

      return {
        data: resJson,
      };
    } else {
      const error = await getError(res);
      return { error };
    }
  };

export const deleteImage =
  (token: string) =>
  async (target: string, id: string, key: string, index: number) => {
    const url = "/delete-image";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const res = await fetch(apiUrl + url, {
      method: "POST",
      headers: token
        ? { ...headers, Authorization: `Bearer ${token}` }
        : headers,
      referrerPolicy: "no-referrer",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
      body: JSON.stringify({ target, id, key, index }),
    });

    if (res.ok) {
      const resJson: { value: Array<string>; message: string } =
        await res.json();

      return {
        data: resJson,
      };
    } else {
      return {
        error: "not found",
      };
    }
  };

export const addCompanyWork =
  (token: string) =>
  async (companyId: string, image: string | Blob, text: string) => {
    let data = new FormData();
    data.append("companyId", companyId);
    data.append("image", image);
    data.append("text", text);

    const url = "/add-company-work";
    const headers = {
      Accept: "application/json",
      // "Content-Type": 不填
    };
    const res = await fetch(apiUrl + url, {
      method: "POST",
      headers: token
        ? { ...headers, Authorization: `Bearer ${token}` }
        : headers,
      referrerPolicy: "no-referrer",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
      body: data,
    });

    if (res.ok) {
      const resJson: { works: Array<CompanyWork> } = await res.json();

      return {
        data: resJson,
      };
    } else {
      const error = await getError(res);
      return { error };
    }
  };

export const deleteCompanyWork =
  (token: string) => async (companyId: string, workId: string) => {
    const url = "/delete-company-work";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const res = await fetch(apiUrl + url, {
      method: "POST",
      headers: token
        ? { ...headers, Authorization: `Bearer ${token}` }
        : headers,
      referrerPolicy: "no-referrer",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
      body: JSON.stringify({ companyId, workId }),
    });

    if (res.ok) {
      const resJson: { works: Array<CompanyWork> } = await res.json();

      return {
        data: resJson,
      };
    } else {
      return {
        error: "not found",
      };
    }
  };

export const uploadJobImage =
  (token: string) =>
  async (
    jobId: string,
    type: "image" | "wechatShareImage",
    image: string | Blob
  ) => {
    let data = new FormData();
    data.append("jobId", jobId);
    data.append("type", type);
    data.append("image", image);

    const url = "/set-job-image";
    const headers = {
      Accept: "application/json",
      // "Content-Type": 不填
    };
    const res = await fetch(apiUrl + url, {
      method: "POST",
      headers: token
        ? { ...headers, Authorization: `Bearer ${token}` }
        : headers,
      referrerPolicy: "no-referrer",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
      body: data,
    });

    if (res.ok) {
      const resJson: { [key in typeof type]?: string } & { message: string } =
        await res.json();

      return {
        data: resJson,
      };
    } else {
      const error = await getError(res);
      return { error };
    }
  };

export const updateJobPriority =
  (token: string) =>
  async (jobs: Array<{ _id: string; priority?: number }>) => {
    const url = "/update-job-priority";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const res = await fetch(apiUrl + url, {
      method: "POST",
      headers: token
        ? { ...headers, Authorization: `Bearer ${token}` }
        : headers,
      referrerPolicy: "no-referrer",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
      body: JSON.stringify({ jobs }),
    });

    if (res.ok) {
      const resJson: { jobs: Array<Job> } = await res.json();

      return {
        data: resJson,
      };
    } else {
      return {
        error: "not found",
      };
    }
  };

export const getJobPriorities = (token: string) => async () => {
  const url = "/job-priority";
  const headers = {
    Accept: "application/json",
  };
  const res = await fetch(apiUrl + url, {
    method: "POST",
    headers: token ? { ...headers, Authorization: `Bearer ${token}` } : headers,
    referrerPolicy: "no-referrer",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
  });

  if (res.ok) {
    const resJson: { jobs: Array<Job> } = await res.json();

    return {
      data: resJson,
    };
  } else {
    return {
      error: "not found",
    };
  }
};

export const searchUser = (token: string) => async (filters: Criteria) => {
  const url = "/search-user";
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const res = await fetch(apiUrl + url, {
    method: "POST",
    headers: token ? { ...headers, Authorization: `Bearer ${token}` } : headers,
    referrerPolicy: "no-referrer",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
    body: JSON.stringify({ filters }),
  });

  if (res.ok) {
    const resJson: { users: Array<UserView> } = await res.json();

    return {
      data: resJson,
    };
  } else {
    return {
      error: "not found",
    };
  }
};

export const updateTags =
  (token: string) =>
  async (update: Array<{ _id: string; tags: Array<string> }>) => {
    const url = "/update-tags";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const res = await fetch(apiUrl + url, {
      method: "POST",
      headers: token
        ? { ...headers, Authorization: `Bearer ${token}` }
        : headers,
      referrerPolicy: "no-referrer",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
      body: JSON.stringify({ update }),
    });

    if (res.ok) {
      const resJson: { updated: unknown } = await res.json();

      return {
        data: resJson,
      };
    } else {
      return {
        error: "not found",
      };
    }
  };

export const getTags = (token: string) => async () => {
  const url = "/tags";
  const headers = {
    Accept: "application/json",
  };
  const res = await fetch(apiUrl + url, {
    method: "POST",
    headers: token ? { ...headers, Authorization: `Bearer ${token}` } : headers,
    referrerPolicy: "no-referrer",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
  });

  if (res.ok) {
    const resJson: { tags: Array<string> } = await res.json();

    return {
      data: resJson,
    };
  } else {
    return {
      error: "not found",
    };
  }
};

export const renameTag =
  (token: string) => async (name: string, newName: string) => {
    const url = "/rename-tag";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const res = await fetch(apiUrl + url, {
      method: "POST",
      headers: token
        ? { ...headers, Authorization: `Bearer ${token}` }
        : headers,
      referrerPolicy: "no-referrer",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
      body: JSON.stringify({ name, newName }),
    });

    if (res.ok) {
      const resJson: { updated: { n: number; nModified: number; ok: number } } =
        await res.json();

      return {
        data: resJson,
      };
    } else {
      return {
        error: "not found",
      };
    }
  };

const service = {
  //   getUser(userId) {
  //     return authPost("/user", { userId }).then((res) => {
  //       let user = res.data.user;
  //       return user;
  //     });
  //   },
  //   getCompanies() {
  //     return authPost("/companies").then((res) => {
  //       let companies = res.data.companies;
  //       store.setCompanies(companies);
  //       return companies;
  //     });
  //   },
  //   getCompany(companyId) {
  //     return authPost("/company", { companyId }).then((res) => {
  //       let company = res.data.company;
  //       company.loaded = true;
  //       store.setCompany(company);
  //       return company;
  //     });
  //   },
  //   updateCompany(company) {
  //     return authPost("/update-company", { company });
  //   },
  //   deleteCompany(companyId) {
  //     return authPost("/delete-company", { companyId }).then((res) => {
  //       store.deleteCompany(companyId);
  //     });
  //   },
  //   newCompany() {
  //     return authPost("/new-company").then((res) => {
  //       let company = res.data.company;
  //       store.newCompany(company);
  //       return company;
  //     });
  //   },
  //   newJob(companyId) {
  //     return authPost("/new-job", { companyId }).then((res) => {
  //       let job = res.data.job;
  //       store.newJob(companyId, job);
  //       return job;
  //     });
  //   },
  //   updateJob(job) {
  //     return authPost("/update-job", { job });
  //   },
  //   deleteJob(companyId, jobId) {
  //     return authPost("/delete-job", { companyId, jobId }).then((res) => {
  //       store.deleteJob(companyId, jobId);
  //     });
  //   },
  //   getJob(jobId) {
  //     return authPost("/job", { jobId }).then((res) => {
  //       return res.data.job;
  //     });
  //   },
  //   adminSignIn(email, password) {
  //     return sendPost("/sign-in", { email, password }).then(updateToken);
  //   },
  //   adminSignUp(email, password, code) {
  //     return sendPost("/sign-up", { email, password, code }).then(updateToken);
  //   },
  //   toggleHighlight(job) {
  //     return authPost("/set-highlight", {
  //       jobId: job._id,
  //       highlight: !job.highlight,
  //     }).then(() => {
  //       store.toggleHighlight(job);
  //     });
  //   },
  //   uploadImage(target, id, key, images) {
  //     let data = new FormData();
  //     for (let i = 0; i < images.length; ++i) {
  //       data.append("images", images[i]);
  //     }
  //     data.append("target", target);
  //     data.append("id", id);
  //     data.append("key", key);
  //     return authPost("/upload-image", data, {
  //       headers: { "content-type": "multipart/form-data" },
  //     }).then((res) => {
  //       let value = res.data.value;
  //       store.updateField(target, id, key, value);
  //     }, multerErrorHandler);
  //   },
  //   deleteImage(target, id, key, index) {
  //     return authPost("/delete-image", { target, id, key, index }).then((res) => {
  //       let value = res.data.value;
  //       store.updateField(target, id, key, value);
  //     });
  //   },
  //   addWork(companyId, image, text) {
  //     let data = new FormData();
  //     data.append("companyId", companyId);
  //     data.append("image", image);
  //     data.append("text", text);
  //     return authPost("/add-company-work", data, {
  //       headers: { "content-type": "multipart/form-data" },
  //     }).then((res) => {
  //       let works = res.data.works;
  //       store.updateCompanyWorks(companyId, works);
  //     }, multerErrorHandler);
  //   },
  //   deleteWork(companyId, workId) {
  //     return authPost("/delete-company-work", { companyId, workId }).then(
  //       (res) => {
  //         let works = res.data.works;
  //         store.updateCompanyWorks(companyId, works);
  //       }
  //     );
  //   },
  //   uploadJobImage(jobId, type, image) {
  //     let data = new FormData();
  //     data.append("jobId", jobId);
  //     data.append("type", type);
  //     data.append("image", image);
  //     return authPost("/set-job-image", data, {
  //       headers: { "content-type": "multipart/form-data" },
  //     }).then((res) => {
  //       return res.data[type];
  //     }, multerErrorHandler);
  //   },
  //   updateJobPriority(jobs) {
  //     return authPost("/update-job-priority", { jobs }).then((res) => {
  //       return res.data.jobs;
  //     });
  //   },
  //   getJobPriorities() {
  //     return authPost("/job-priority").then((res) => {
  //       return res.data.jobs;
  //     });
  //   },
  //   searchUser(filters) {
  //     return authPost("/search-user", { filters }).then((res) => {
  //       return res.data.users;
  //     });
  //   },
  //   updateTags(update) {
  //     return authPost("/update-tags", { update }).then((res) => {
  //       return res.data.updated;
  //     });
  //   },
  //   getTags() {
  //     return authPost("/tags").then((res) => {
  //       return res.data.tags;
  //     });
  //   },
  //   renameTag(name, newName) {
  //     return authPost("/rename-tag", { name, newName });
  //   },
};

export default service;
