import React, { useRef, useState, KeyboardEvent } from "react";
import { FaTimes } from "react-icons/fa";
import clsx from "clsx";
import { includesIgnoreCase, equalsIgnoreCase, round } from "utils";
import styles from "./tag.module.scss";

interface Props {
  tags: Array<string>;
  options: Array<string>;
  onChange: (tags: Array<string>) => void;
}

const TagEditor = ({ tags, options, onChange }: Props) => {
  const [newTag, setNewTag] = useState("");
  const [filter, setFilter] = useState("");
  const [optionIndex, setOptionIndex] = useState(-1);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const isComposingRef = useRef(false);

  const isOptionsListOpen = !!filter;

  const getMatchedOptions = () => {
    if (!filter) return [];
    let matched = options.filter((o) => includesIgnoreCase(o, filter));
    matched = matched.filter(
      (t) => !tags.some((tag) => equalsIgnoreCase(tag, t))
    );
    return matched;
  };

  const matchedOptions = getMatchedOptions();

  const addTag = (tag: string) => {
    tag = tag ? tag.trim() : "";
    setFilter("");
    setNewTag("");
    setOptionIndex(-1);

    if (tag && !tags.some((t) => equalsIgnoreCase(t, tag))) {
      onChange([...tags, tag]);
    }
  };

  const removeTag = (index: number) => {
    onChange([...tags.slice(0, index), ...tags.slice(index + 1)]);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (isComposingRef.current) {
      return;
    }

    if (e.code === "Enter") {
      e.preventDefault();

      if (optionIndex >= 0 && optionIndex < matchedOptions.length) {
        addTag(matchedOptions[optionIndex]);
      } else {
        addTag(newTag);
      }

      return;
    }

    if (e.code === "Space") {
      e.preventDefault();
      addTag(newTag);
      return;
    }

    if (e.code === "Backspace" && !newTag) {
      onChange(tags.slice(0, tags.length - 1));
      return;
    }

    if (e.code === "ArrowDown" || e.code === "ArrowUp") {
      setOptionIndex(
        round(
          optionIndex + (e.code === "ArrowDown" ? 1 : -1),
          matchedOptions.length
        )
      );
    }
  };

  const handleChange = (value: string) => {
    setNewTag(value);
    if (!isComposingRef.current) {
      setFilter(value);
    }
  };

  const handleClick = () => {
    inputRef.current?.focus();
  };

  return (
    <div className={styles["tag-editor-wrapper"]}>
      <div className={styles["tag-editor"]} onClick={handleClick}>
        {tags.map((t, i) => (
          <span className={styles["tag-item"]} key={t}>
            <span className={styles["tag-text"]}>{t}</span>
            <button
              className={styles["light-button"]}
              onClick={() => removeTag(i)}
            >
              <FaTimes />
            </button>
          </span>
        ))}

        <input
          ref={inputRef}
          type="text"
          className={styles["tag-input"]}
          onKeyDown={handleKeyDown}
          value={newTag}
          onChange={(e) => handleChange(e.target.value)}
          placeholder="输入标签, 以空格分隔"
          onCompositionStart={() => (isComposingRef.current = true)}
          onCompositionEnd={() => {
            isComposingRef.current = false;
            handleChange(newTag);
          }}
        />
        {isOptionsListOpen && (
          <ul className={styles["tag-options"]}>
            {matchedOptions.map((option, i) => (
              <li
                key={option}
                className={clsx(
                  styles["tag-option"],
                  optionIndex === i && styles.highlight
                )}
                onClick={(e) => {
                  addTag(option);
                  e.preventDefault();
                }}
              >
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default TagEditor;
