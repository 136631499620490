import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useRecoilState, useRecoilValue } from "recoil";
import clsx from "clsx";
import { FaChevronLeft, FaPlus } from "react-icons/fa";
import { getCompanies, newCompany } from "api";
import {
  companyFoldedListState,
  selectedCompanyIdState,
  tokenState,
} from "state";
import Company from "./Company";
import styles from "./index.module.scss";

const Page = () => {
  const token = useRecoilValue(tokenState);
  const history = useHistory();

  const [selectedId, setSelectedId] = useRecoilState(selectedCompanyIdState);
  const [companies, setCompanies] = useRecoilState(companyFoldedListState);

  const createCompany = async () => {
    const { data, error } = await newCompany(token)();
    if (data) {
      const { _id, name } = data.company;
      setCompanies([{ _id, name }, ...companies]);
      setSelectedId(_id);
    }

    if (error) {
      alert(`创建公司失败!\n错误信息: ${error}`);
    }
  };

  const removeCompany = (companyId: string) => {
    const selectedIndex = companies.findIndex(({ _id }) => _id === selectedId);
    const list = companies.filter(({ _id }) => _id !== companyId);
    setCompanies(list);

    if (selectedId === companyId)
      setSelectedId(
        list.length ? list[Math.min(selectedIndex, list.length - 1)]._id : ""
      );
  };

  useEffect(() => {
    const loadCompanies = async () => {
      const { data, error } = await getCompanies(token)();
      if (data) {
        setCompanies(data.companies);
        setSelectedId((selectedId) =>
          data.companies?.length
            ? data.companies.some(({ _id }) => _id === selectedId)
              ? selectedId
              : data.companies[0]._id
            : ""
        );
      }

      if (error) {
        alert(`加载公司失败!\n错误信息: ${error}`);
      }
    };
    loadCompanies();
  }, [token, setCompanies, setSelectedId]);

  return (
    <div className={styles["companies-page"]}>
      <div className={styles.sidebar}>
        <button
          className={styles["back-to-home"]}
          onClick={() => history.push("/")}
        >
          <FaChevronLeft />
          返回首页
        </button>
        <button className={styles["add-company"]} onClick={createCompany}>
          <FaPlus />
          添加公司
        </button>
        <div className={styles["company-list"]}>
          {companies.map((company) => (
            <div
              key={company._id}
              onClick={() => setSelectedId(company._id)}
              className={clsx(
                styles["company-item"],
                company._id === selectedId && styles.active
              )}
            >
              {company.name}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.content}>
        {selectedId && (
          <Company id={selectedId} onCompanyDeleted={removeCompany}></Company>
        )}
      </div>
    </div>
  );
};

export default Page;
