import React, { useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { GENDER_OPTIONS, DEGREE_OPTIONS, OCCUPATION_OPTIONS } from "constant";
import { isEdited } from "utils/isEdited";
import {
  formatDate,
  formatLocation,
  formatTime,
  toTodayInYear,
} from "utils/format";
import TagEditor from "./TagEditor";
import TagsView from "./TagsView";
import styles from "./UserRow.module.scss";

interface Props {
  user: UserView;
  tags: Array<string>;
  newTags: Array<string>;
  extraUserInfo: ExtraUserInfo;
  editingTags: boolean;
  allTags: Array<string>;
  onTagsChange: (user: UserView, tags: Array<string>) => void;
}
const UserRow = ({
  user,
  tags,
  newTags,
  extraUserInfo,
  editingTags,
  allTags,
  onTagsChange,
}: Props) => {
  const [folded, setFolded] = useState(true);

  const edited = isEdited({ tags, newTags });

  return (
    <div className={clsx(styles["user-row"], edited && styles.edited)}>
      {edited && <div className={styles["edited-banner"]}></div>}
      <div className={styles["basic-info-row"]}>
        <Link to={`/user/${user._id}`} className={styles["basic-info"]}>
          <span className={styles.name}>{user.name}</span>
          <span className={styles.items}>
            <span className={styles.item}>{GENDER_OPTIONS[user.gender]}</span>
            <span className={styles.item}>
              {typeof extraUserInfo.degree !== "undefined" &&
                DEGREE_OPTIONS[extraUserInfo.degree]}
            </span>
            <span className={styles.item}>
              {toTodayInYear(user.birthday)}岁
            </span>
            <span className={styles.item}>
              工作{toTodayInYear(extraUserInfo.workStartYear)}年
            </span>
            <span className={styles.item}>
              {user.occupation
                ? user.occupation.type === 0
                  ? user.occupation.name
                  : OCCUPATION_OPTIONS[user.occupation.type]
                : ""}
            </span>
          </span>
        </Link>
        <div className={clsx(styles.items, styles["text-secondary"])}>
          <span className={styles.item}>
            期望工作地: {formatLocation(user.expectedLocation)}
          </span>
          <span className={styles.item}>
            最近更新: {formatDate(user.updatedAt)}
          </span>
        </div>
      </div>
      <div className={styles.row}>
        {/* <div className={styles["work-exps text-secondary col-xs-12 col-lg-6"]}> */}
        <div className={clsx(styles["work-exps"], styles["text-secondary"])}>
          {user.workExps.map((exp, i) => (
            <div className={clsx(styles["work-exp"], styles.items)} key={i}>
              <span className={styles.item}>
                {formatTime(exp.startTime)} - {formatTime(exp.endTime)}
              </span>
              <span className={styles.item}>{exp.company}</span>
              <span className={styles.item}>{exp.department}</span>
              <span className={styles.item}>{exp.position}</span>
            </div>
          ))}
        </div>

        <div
          className={clsx(styles["education-exps"], styles["text-secondary"])}
        >
          {user.educationExps.map((exp, i) => (
            <div className={clsx(styles["education-exp"], styles.item)} key={i}>
              <span className={styles.item}>
                {formatTime(exp.startTime)} - {formatTime(exp.endTime)}
              </span>
              <span className={styles.item}>{exp.school}</span>
              <span className={styles.item}>{exp.major}</span>
              <span className={styles.item}>{DEGREE_OPTIONS[exp.degree]}</span>
            </div>
          ))}
        </div>
      </div>
      {user.jobs && user.jobs.length >= 1 && (
        <div className={clsx(styles["applied-jobs"], styles["text-secondary"])}>
          <div className={styles.title}>
            已申请职位{" "}
            <span className={styles.link} onClick={() => setFolded(!folded)}>
              {folded ? `显示全部(${user.jobs.length})` : "折叠职位"}
            </span>
          </div>
          {(folded ? user.jobs.slice(0, 1) : user.jobs).map((job, index) => (
            <div className={clsx(styles["job-pill"], styles.items)} key={index}>
              <span className={styles.item}>{job.company}</span>
              <span className={styles.item}>{job.name}</span>
            </div>
          ))}
        </div>
      )}
      <div className={styles["tag-row"]}>
        {editingTags ? (
          <TagEditor
            tags={newTags ?? []}
            onChange={(tags) => onTagsChange(user, tags)}
            options={allTags}
          />
        ) : (
          <TagsView tags={user.tags ?? []} />
        )}
      </div>
    </div>
  );
};

export default UserRow;
