import React from "react";
import { OCCUPATION_OPTIONS } from "constant";
import styles from "./SelectOccupation.module.scss";

interface Props {
  value: Array<number>;
  onChange: (value: Array<number>) => void;
}

const allIndexes = OCCUPATION_OPTIONS.map((o, index) => index);

const SelectOccupation = ({ value, onChange }: Props) => {
  const reverseSelection = () =>
    onChange(allIndexes.filter((item) => !value.includes(item)));

  return (
    <>
      <div className={styles.buttons}>
        <button
          className={styles["light-button"]}
          onClick={() => onChange([...allIndexes])}
        >
          全选
        </button>

        <button className={styles["light-button"]} onClick={() => onChange([])}>
          全不选
        </button>
        <button className={styles["light-button"]} onClick={reverseSelection}>
          反选
        </button>
      </div>
      <div className={styles.list}>
        {OCCUPATION_OPTIONS.map((occupation, index) => (
          <label key={index} className={styles["checkbox-label"]}>
            <input
              type="checkbox"
              value={index}
              checked={value.includes(index)}
              onChange={() =>
                onChange(
                  value.includes(index)
                    ? value.filter((item) => item !== index)
                    : [...value, index]
                )
              }
            />
            &nbsp;{occupation}
          </label>
        ))}
      </div>
    </>
  );
};

export default SelectOccupation;
