import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { RequestStatus, equalsIgnoreCase } from "utils";
import { KeyCode } from "keycode";
import LoadingSpinner from "components/LoadingSpinner";
import styles from "./index.module.scss";

interface Props {
  isOpen: boolean;
  tags: Array<string>;
  activeTag: string;
  onRename: (tag: string, newTag: string) => void;
  onCancel: () => void;
}

const RenameTagModal = ({
  isOpen,
  tags,
  activeTag,
  onRename,
  onCancel,
}: Props) => {
  const [text, setText] = useState("");
  const [requestStatus, setRequestStatus] = useState(RequestStatus.Default);

  const isPending = requestStatus === RequestStatus.Pending;

  const hasConflict = () => {
    return tags.some((t) => equalsIgnoreCase(t, text.trim()));
  };

  const message = !text
    ? `请输入新标签名.`
    : hasConflict()
    ? `新标签名不得与已有标签重名.`
    : `确定将标签"${activeTag}"重命名为"${text}"吗?`;
  const isValid: boolean = !!text && !hasConflict();

  const rename = () => {
    setRequestStatus(RequestStatus.Pending);

    onRename(activeTag, text.trim());
  };

  const onKeyDown = (e: { keyCode: number }) => {
    if (e.keyCode === KeyCode.Enter && isValid && !isPending) {
      rename();
    }
  };

  return (
    <Modal className={styles.modal} isOpen={isOpen}>
      <ModalHeader>重命名标签{'"' + activeTag + '"'}</ModalHeader>
      <ModalBody>
        <div className={styles.field}>
          <div className={styles.title}>新标签名</div>
          <input
            type="text"
            onChange={(e) => setText(e.target.value)}
            onKeyDown={onKeyDown}
          />
        </div>
        <p className={styles.message}>{message}</p>
      </ModalBody>
      <ModalFooter>
        {isPending ? (
          <button
            className={styles["primary-button"]}
            onClick={rename}
            disabled
          >
            <LoadingSpinner /> &nbsp;重命名中
          </button>
        ) : (
          <button
            className={styles["primary-button"]}
            onClick={rename}
            disabled={!isValid}
          >
            确定
          </button>
        )}

        <button
          className={styles["default-button"]}
          onClick={onCancel}
          disabled={isPending}
        >
          取消
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default RenameTagModal;
