import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getUser } from "api";
import { useToken } from "state/hooks";
import Loading from "components/Loading";
import User from "./User";

const Page = () => {
  const token = useToken();
  const { userId } = useParams<{ userId: string }>();
  const [user, setUser] = useState<UserView>();

  useEffect(() => {
    const loadUser = async () => {
      const { data, error } = await getUser(token)(userId);

      if (data) {
        setUser(data.user);
      }

      if (error) {
        console.log(error);
      }
    };

    loadUser();
  }, [userId, token]);
  return user ? <User user={user} /> : <Loading />;
};

export default Page;
