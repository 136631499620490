import React, { useState } from "react";
import clsx from "clsx";
import LoginForm from "./LoginForm";
import SignUpForm from "./SignUpForm";
import styles from "./index.module.scss";

const Login = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className={styles["login-page"]}>
      <div className={styles.tab}>
        <div className={styles["tab-buttons"]}>
          <div
            className={clsx(
              styles["tab-button"],
              activeTab === 0 && styles.active
            )}
            onClick={() => setActiveTab(0)}
          >
            登录
          </div>
          <div
            className={clsx(
              styles["tab-button"],
              activeTab === 1 && styles.active
            )}
            onClick={() => setActiveTab(1)}
          >
            注册
          </div>
        </div>
        <LoginForm active={activeTab === 0} />
        <SignUpForm active={activeTab === 1} />
      </div>
    </div>
  );
};

export default Login;
