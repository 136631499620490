export function backendResource(url: string) {
  return process.env.REACT_APP_API_URL + "/" + url;
}

export const RequestStatus = {
  Default: 0,
  Pending: 1,
  Success: 2,
  Failed: 3,
};

// export function range(end) {
//   return [...Array(end).keys()];
// }

export function equalsIgnoreCase(a: string, b: string) {
  return a.toUpperCase() === b.toUpperCase();
}

export function includesIgnoreCase(a: string, b: string) {
  return a.toUpperCase().includes(b.toUpperCase());
}

export function clone(o: any) {
  if (!o) return o;
  return JSON.parse(JSON.stringify(o));
}

export function round(v: number, max: number) {
  while (v < 0) v += max;
  return v % max;
}

// export function forOwn(obj: Object, iteratee) {
//   Object.keys(obj).forEach((key) => {
//     iteratee(obj[key], key);
//   });
// }

// export function mapOwn(obj: Object, iteratee) {
//   let arr = [];
//   forOwn(obj, (val, key) => {
//     arr.push(iteratee(val, key));
//   });
//   return arr;
// }
