import React from "react";
import { PCD, REGIONS } from "constant";
import styles from "./LocationPicker.module.scss";

const anyItem = { code: "0", name: "不限" };
const defaultCode = "0";

interface Props {
  value: ILocation;
  onChange: (value: ILocation) => void;
}

const LocationPicker = ({ value, onChange }: Props) => {
  const {
    region, //, province, city, district
  } = value;
  const province = value.province ?? defaultCode;
  const city = value.city ?? defaultCode;
  const district = value.district ?? defaultCode;

  const getChildren = (code: string) => {
    const pcdMap = PCD.map;
    console.log({ code, node: pcdMap[code] });
    const children = pcdMap[code]?.children ?? [];

    const mapped = children.map((code) => {
      let item = pcdMap[code];
      return {
        code: item.code,
        name: item.name,
      };
    });

    return [anyItem, ...mapped];
  };

  const regions = [anyItem, ...REGIONS];
  const provinces = [anyItem, ...PCD.provinces];
  const cities = getChildren(province);
  const districts = getChildren(city);

  return (
    <div className={styles["location-picker"]}>
      <div className={styles.field}>
        <div className={styles.title}>地区 {region}</div>
        <select
          value={region}
          onChange={(e) =>
            onChange({
              region: e.target.value,
              province: defaultCode,
              city: defaultCode,
              district: defaultCode,
            })
          }
        >
          {regions.map((item) => (
            <option value={item.code} key={item.code}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
      {region === "CN" && (
        <>
          <div className={styles.field}>
            <div className={styles.title}>省 {province}</div>
            <select
              value={province}
              onChange={(e) =>
                onChange({
                  region,
                  province: e.target.value,
                  city: defaultCode,
                  district: defaultCode,
                })
              }
            >
              {provinces.map((item) => (
                <option value={item.code} key={item.code}>
                  {item.code === "0" ? "不限" : item.name}
                </option>
              ))}
            </select>
          </div>
          {province && province !== "0" && (
            <div className={styles.field}>
              <div className={styles.title}>市 {city}</div>
              <select
                value={city}
                onChange={(e) =>
                  onChange({
                    region,
                    province,
                    city: e.target.value,
                    district: defaultCode,
                  })
                }
              >
                {cities.map((item) => (
                  <option value={item.code} key={item.code}>
                    {item.name}
                  </option>
                ))}

                {/* <option v-for="item in cities" value="item.code" key="item.name">
              {item.name}
            </option> */}
              </select>
            </div>
          )}
          {city && city !== "0" && (
            <div className={styles.field}>
              <div className={styles.title}>区 {district}</div>
              <select //v-model="district"
                value={district}
                onChange={(e) =>
                  onChange({ region, province, city, district: e.target.value })
                }
              >
                {districts.map((item) => (
                  <option value={item.code} key={item.code}>
                    {item.name}
                  </option>
                ))}

                {/* <option v-for="item in districts" value="item.code" key="item.name">
              {item.name}
            </option> */}
              </select>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default LocationPicker;
