import moment from "moment";
import { REGION_MAP, PCD } from "constant";

export function formatLocation(location?: ILocation) {
  if (!location || !location.region) return "-";
  var loc = "";
  var region = REGION_MAP[location.region];
  var province = PCD.map[location.province];
  var city = PCD.map[location.city];
  var district = PCD.map[location.district];
  if (region) {
    loc += region.name;
  }
  if (province) {
    loc += "-" + province.name;
  }
  if (city) {
    loc += "-" + city.name;
  }
  if (district) {
    loc += "-" + district.name;
  }
  return loc;
}

export function formatDate(date?: string | Date) {
  if (!date) return "";
  return moment(date).format("YYYY年MM月DD日");
}

export function formatTime(time?: string | Date) {
  if (!time) return "至今";
  return moment(time).format("YYYY年MM月");
}

export function toTodayInYear(date?: string | number | Date) {
  if (!date) return "-";
  return ((Date.now() - new Date(date).valueOf()) / 31536000000).toFixed(1);
}
