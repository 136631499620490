import { atom, atomFamily, selector } from "recoil";

export const tokenState = atom({
  key: "token",
  default: "",
});

export const isAuthenticatedState = selector({
  key: "isAuthenticated",
  get: ({ get }) => !!get(tokenState),
});

export const appErrorState = atomFamily<string, string>({
  key: "appError",
  default: "",
});

// following states
// for search-user, user-list

export const usersState = atomFamily<Array<UserView>, string>({
  key: "users",
  default: [],
});

export const extraUserInfoByIdState = atomFamily<
  Record<string, ExtraUserInfo>,
  string
>({
  key: "extraUserInfoById",
  default: {},
});

export const selectedUserIdsState = atomFamily<Array<string>, string>({
  key: "selectedUserIds",
  default: [],
});

export const isEditingTagsState = atomFamily<boolean, string>({
  key: "isEditingTags",
  default: false,
});

export const tagsByIdState = atomFamily<Record<string, Array<string>>, string>({
  key: "tagsById",
  default: {},
});

export const newTagsByIdState = atomFamily<
  Record<string, Array<string>>,
  string
>({
  key: "newTagsById",
  default: {},
});

// 数据库中的全部tag，去重
export const allTagsState = atomFamily<Array<string>, string>({
  key: "allTags",
  default: [],
});

export const queryStatsState = atomFamily<
  { timeElapsed: number; totalCount: number },
  string
>({
  key: "queryStats",
  default: { timeElapsed: 0, totalCount: 0 },
});

export const paginationState = atomFamily<
  { pageIndex: number; pageSize: number },
  string
>({
  key: "pagination",
  default: { pageIndex: 0, pageSize: 25 },
});

// for company
export const companyFoldedListState = atom<Array<CompanyFolded>>({
  key: "companyFoldedList",
  default: [],
});

export const companyState = atomFamily<Company | undefined, string>({
  key: "company",
  default: undefined,
});

export const selectedCompanyIdState = atom<string>({
  key: "selectedCompanyId",
  default: "",
});
