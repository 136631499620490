import React from "react";
import { useHistory } from "react-router";
import { FaChevronLeft } from "react-icons/fa";
import styles from "./NavBar.module.scss";

const NavBar = () => {
  const history = useHistory();

  return (
    <div className={styles.navbar}>
      <button
        className={styles["light-button"]}
        onClick={() => history.goBack()}
      >
        <FaChevronLeft />
        &nbsp;返回
      </button>
    </div>
  );
};

export default NavBar;
