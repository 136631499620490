import React from "react";
import { Redirect, useLocation } from "react-router";
import { useRecoilState } from "recoil";
import { Form, Field } from "react-final-form";
import clsx from "clsx";
import { required } from "./validators";
import { adminSignIn } from "api";
import { tokenState } from "state";
import styles from "./index.module.scss";
import { FORM_ERROR } from "final-form";

interface Props {
  active: boolean;
}

interface Values {
  email: string;
  password: string;
}

const LoginForm = ({ active }: Props) => {
  const [token, setToken] = useRecoilState(tokenState);
  const location = useLocation<{ from?: Location | string }>();
  const submit = async ({ email, password }: Values) => {
    const { data, error } = await adminSignIn(email, password);
    console.log({ data, error });

    if (data) {
      setToken(data.token);

      // save to localstorage
      localStorage.setItem("token", data.token);
    }
    if (error) return { [FORM_ERROR]: error };
  };

  const from = location.state?.from;

  return token ? (
    <Redirect
      to={
        from && (typeof from === "string" || from.pathname !== "/login")
          ? from
          : "/"
      }
    />
  ) : (
    <Form<Values> onSubmit={submit}>
      {({ handleSubmit, hasValidationErrors, submitError }) => (
        <form
          className={clsx(styles["tab-content"], active && styles.active)}
          onSubmit={handleSubmit}
        >
          <label>
            邮箱:
            <Field
              name="email"
              component="input"
              type="email"
              validate={required}
            />
          </label>
          <label>
            密码:
            <Field
              name="password"
              component="input"
              type="password"
              validate={required}
            />
          </label>
          <div className={styles.err}>{submitError}</div>
          <button
            className={styles["primary-button"]}
            type="submit"
            disabled={hasValidationErrors}
          >
            登录
          </button>
        </form>
      )}
    </Form>
  );
};

export default LoginForm;
