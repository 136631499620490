import React from "react";
import moment from "moment";
import { backendResource } from "utils";
import {
  JOB_CATEGORY_OPTIONS,
  JOB_CONTACT_DISPLAY,
  JOB_DEGREE_REQUIREMENT_OPTIONS,
  JOB_LANGUAGE_TYPE_OPTIONS,
  JOB_SALARY_TYPE_OPTIONS,
  JOB_TYPE_OPTIONS,
  JOB_WORK_EXP_OPTIONS,
} from "constant";
import NavBar from "components/NavBar";
import LocationPicker from "components/LocationPicker";
import styles from "./EditJob.module.scss";

const simplifySalary = (num?: number, salaryType?: number) => {
  if (!num) return num;

  // 年薪
  if (salaryType === 2)
    return num >= 10000 ? +(num / 10000).toFixed(0) + "万" : num + "元";

  return num >= 1000 ? +(num / 1000).toFixed(0) + "K" : num + "元";
};

const previewSalary = (job: Job) => {
  if (!job || job.salaryType === 0) return "";

  const min = simplifySalary(job.salaryMin, job.salaryType);
  const max = simplifySalary(job.salaryMax, job.salaryType);
  if (!min && !max) {
    return "未设置";
  }

  let str = job.salaryType === 2 ? "年薪 " : "";

  str += !min ? `${max}以下` : !max ? `${min}以上` : `${min}-${max}`;

  if (job.salaryNote) str += ` | ${job.salaryNote}`;

  return str;
};

interface Props {
  job: JobWithStats;
  onChange: (value: JobWithStats) => void;
  onSave: (job: Job) => void;
  onDelete: () => void;
  onToggleHighlight: () => void;
  onUploadImage: (type: "image" | "wechatShareImage", files: FileList) => void;
  onViewApplicants: () => void;
}

const EditJob = ({
  job,
  onChange,
  onSave,
  onDelete,
  onToggleHighlight,
  onUploadImage,
  onViewApplicants,
}: Props) => {
  const setPropValue = <Key extends keyof JobWithStats>(
    name: Key,
    value: JobWithStats[Key]
  ) => onChange({ ...job, [name]: value });

  return (
    <div className={styles["job-page"]}>
      <NavBar />
      <div className={styles["page-content"]}>
        <div className={styles["top-button-row"]}>
          <button
            className={styles["default-button"]}
            onClick={() => onSave(job)}
          >
            保存
          </button>
          <button className={styles["danger-button"]} onClick={onDelete}>
            删除
          </button>
          <button
            className={styles["default-button"]}
            onClick={onViewApplicants}
          >
            查看申请人
            {job.applicantCount && (
              <span className={styles.badge}>{job.applicantCount}</span>
            )}
          </button>
          <button
            className={styles["default-button"]}
            onClick={onToggleHighlight}
          >
            {job.highlight ? "已精选" : "添加精选"}
          </button>
        </div>

        <div className={styles["job-body"]}>
          <div className={styles.field}>
            <div className={styles.title}>
              头图 (仅精选职位需要设置; 横纵比2:1)
            </div>
            <input
              type="file"
              accept=".jpg, .jpeg, .png"
              onChange={(e) => {
                if (e.target.files) onUploadImage("image", e.target.files);
              }}
            />
            {job.image && (
              <img
                className={styles.image}
                src={backendResource(job.image)}
                alt="job"
              />
            )}
          </div>

          <div className={styles.field}>
            <div className={styles.title}>
              分享图片 (用于微信转发; 横纵比5:4)
            </div>
            <input
              type="file"
              accept=".jpg, .jpeg, .png"
              onChange={(e) => {
                if (e.target.files)
                  onUploadImage("wechatShareImage", e.target.files);
              }}
            />
            {job.wechatShareImage && (
              <img
                className={styles["wechat-share-image"]}
                src={backendResource(job.wechatShareImage)}
                alt="wechat-share"
              />
            )}
          </div>

          <div className={styles.field}>
            <div className={styles.title}>ID: {job._id}</div>
          </div>

          <div className={styles.field}>
            <div className={styles.title}>转发简历至邮箱</div>
            <div className={styles["content-flow"]}>
              <div className={styles.field}>
                <div className={styles.title}>邮箱</div>
                <input
                  type="text"
                  value={job.hrEmail}
                  onChange={(e) => setPropValue("hrEmail", e.target.value)}
                />
              </div>

              <div className={styles.field}>
                <div className={styles.title}>显示被推荐人联系方式</div>
                {JOB_CONTACT_DISPLAY.map((text, index) => (
                  <label className={styles["radio-label"]} key={index}>
                    <input
                      type="radio"
                      name="contactDisplay"
                      value={index}
                      checked={job.contactDisplay === index}
                      onChange={() => setPropValue("contactDisplay", index)}
                    />
                    &nbsp;{text}
                  </label>
                ))}
              </div>
            </div>
          </div>

          <div className={styles.field}>
            <div className={styles.title}>截止日期</div>
            <input
              type="date"
              value={
                job.deadline ? moment(job.deadline).format("YYYY-MM-DD") : ""
              }
              onChange={(e) =>
                setPropValue("deadline", moment(e.target.value).toISOString())
              }
            />
          </div>

          <div className={styles.field}>
            <div className={styles.title}>名称</div>
            <input
              type="text"
              value={job.name}
              onChange={(e) => setPropValue("name", e.target.value)}
            />
          </div>

          <div className={styles.field}>
            <div className={styles.title}>地点</div>
            <LocationPicker
              value={
                job.location ?? {
                  region: "CN",
                  province: "0",
                  city: "0",
                  district: "0",
                }
              }
              onChange={(value) => setPropValue("location", value)}
            />
          </div>

          <div className={styles["content-flow"]}>
            <div className={styles.field}>
              <div className={styles.title}>工作类别</div>
              <select
                value={job.category}
                onChange={(e) => setPropValue("category", +e.target.value)}
              >
                {JOB_CATEGORY_OPTIONS.map((text, index) => (
                  <option key={index} value={index}>
                    {text}
                  </option>
                ))}
              </select>
            </div>

            <div className={styles.field}>
              <div className={styles.title}>工作性质</div>
              <select
                value={job.type}
                onChange={(e) => setPropValue("type", +e.target.value)}
              >
                {JOB_TYPE_OPTIONS.map((text, index) => (
                  <option key={index} value={index}>
                    {text}
                  </option>
                ))}
              </select>
            </div>

            <div className={styles.field}>
              <div className={styles.title}>学历要求</div>
              <select
                value={job.degreeRequirement}
                onChange={(e) =>
                  setPropValue("degreeRequirement", +e.target.value)
                }
              >
                {JOB_DEGREE_REQUIREMENT_OPTIONS.map((text, index) => (
                  <option key={index} value={index}>
                    {text}
                  </option>
                ))}
              </select>
              <label className={styles.label}>
                <span> 及以上 </span>
                <input
                  type="checkbox"
                  checked={job.degreeIncludeAbove}
                  onChange={() =>
                    setPropValue("degreeIncludeAbove", !job.degreeIncludeAbove)
                  }
                />
              </label>
            </div>

            <div className={styles.field}>
              <div className={styles.title}>工作经验</div>
              <select
                value={job.workExp}
                onChange={(e) => setPropValue("workExp", +e.target.value)}
              >
                {Object.entries(JOB_WORK_EXP_OPTIONS).map(([key, value]) => (
                  <option key={value} value={value}>
                    {key}
                  </option>
                ))}
              </select>
            </div>

            <div className={styles.field}>
              <div className={styles.title}>语言要求</div>
              <div className={styles.language}>
                <select
                  value={job.languageType}
                  onChange={(e) =>
                    setPropValue("languageType", +e.target.value)
                  }
                >
                  {JOB_LANGUAGE_TYPE_OPTIONS.map((text, index) => (
                    <option key={index} value={index}>
                      {text}
                    </option>
                  ))}
                </select>
                {job.languageType === 1 && (
                  <input
                    type="text"
                    value={job.language}
                    onChange={(e) => setPropValue("language", e.target.value)}
                  />
                )}
              </div>
            </div>
          </div>

          <div className={styles.field}>
            <div className={styles.title}>薪资待遇</div>
            <div className={styles["content-flow"]}>
              <div className={styles.field}>
                <div className={styles.title}>类型</div>
                <select
                  value={job.salaryType}
                  onChange={(e) => setPropValue("salaryType", +e.target.value)}
                >
                  {JOB_SALARY_TYPE_OPTIONS.map((text, index) => (
                    <option key={index} value={index}>
                      {text}
                    </option>
                  ))}
                </select>
              </div>

              {!!job.salaryType && (
                <div className={styles["content-flow"]}>
                  <div className={styles.field}>
                    <div className={styles.title}>最低</div>
                    <input
                      type="number"
                      value={job.salaryMin}
                      onChange={(e) =>
                        setPropValue("salaryMin", +e.target.value)
                      }
                    />
                  </div>

                  <div className={styles.field}>
                    <div className={styles.title}>最高</div>
                    <input
                      type="number"
                      value={job.salaryMax}
                      onChange={(e) =>
                        setPropValue("salaryMax", +e.target.value)
                      }
                    />
                  </div>

                  <div className={styles.field}>
                    <div className={styles.title}>备注</div>
                    <input
                      type="text"
                      value={job.salaryNote}
                      onChange={(e) =>
                        setPropValue("salaryNote", e.target.value)
                      }
                      placeholder="额外信息"
                    />
                  </div>

                  <div className={styles.field}>
                    <div className={styles.title}>预览</div>
                    <div className={styles["salary-preview"]}>
                      {previewSalary(job)}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={styles.field}>
            <div className={styles.title}>职位描述</div>
            <textarea
              value={job.jobDescription}
              onChange={(e) => setPropValue("jobDescription", e.target.value)}
            />
          </div>

          <div className={styles.field}>
            <div className={styles.title}>公司福利</div>
            <textarea
              value={job.benefit}
              onChange={(e) => setPropValue("benefit", e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditJob;
