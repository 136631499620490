import React from "react";
import {
  GENDER_OPTIONS,
  OCCUPATION_OPTIONS,
  SALARY_TYPE,
  DEGREE_OPTIONS,
  LANGUAGE_LEVEL_OPTIONS,
  SOFTWARE_LEVEL_OPTIONS,
  SOFTWARE_TYPE_OPTIONS,
} from "constant";
import { formatDate, formatLocation, formatTime } from "utils/format";
import NavBar from "components/NavBar";
import styles from "./index.module.scss";

interface Props {
  user: UserView;
}

const User = ({ user }: Props) => {
  return (
    <div className={styles["user-page"]}>
      <NavBar />
      <div className={styles["page-content"]}>
        <section>
          <div className={styles["section-title"]}>基本信息</div>
          <div className={styles.field}>
            <div className={styles.title}>姓名</div>
            <div className={styles.content}>{user.name}</div>
          </div>
          <div className={styles.field}>
            <div className={styles.title}>性别</div>
            <div className={styles.content}>{GENDER_OPTIONS[user.gender]}</div>
          </div>
          <div className={styles.field}>
            <div className={styles.title}>出生日期</div>
            <div className={styles.content}>{formatDate(user.birthday)}</div>
          </div>
          <div className={styles.field}>
            <div className={styles.title}>职业</div>
            {user.occupation ? (
              <div className={styles.content}>
                {user.occupation.type === 0
                  ? user.occupation.name
                  : OCCUPATION_OPTIONS[user.occupation.type]}
              </div>
            ) : (
              <div className={styles["no-content"]}>-</div>
            )}
          </div>
          <div className={styles.field}>
            <div className={styles.title}>手机</div>
            <div className={styles.content}>{user.phone}</div>
          </div>
          <div className={styles.field}>
            <div className={styles.title}>邮箱</div>
            <div className={styles.content}>{user.email}</div>
          </div>
        </section>

        <section>
          <div className={styles["section-title"]}>求职信息</div>
          <div className={styles.field}>
            <div className={styles.title}>到岗需时</div>
            {typeof user.timeToOnboard !== "undefined" ? (
              <div className={styles.content}>{user.timeToOnboard}月</div>
            ) : (
              <div className={styles["no-content"]}>-</div>
            )}
          </div>
          <div className={styles.field}>
            <div className={styles.title}>期望工作地点</div>
            {user.expectedLocation.region ? (
              <div className={styles.content}>
                {formatLocation(user.expectedLocation)}
              </div>
            ) : (
              <div className={styles["no-content"]}>-</div>
            )}
          </div>
          <div className={styles.field}>
            <div className={styles.title}>目前年薪</div>

            {user.currentSalary ? (
              <div className={styles.content}>
                {SALARY_TYPE[user.currentSalaryType]} {user.currentSalary}
              </div>
            ) : (
              <div className={styles["no-content"]}>-</div>
            )}
          </div>
          <div className={styles.field}>
            <div className={styles.title}>期望年薪</div>
            {user.expectedSalary ? (
              <div className={styles.content}>
                {SALARY_TYPE[user.expectedSalaryType]} {user.expectedSalary}
              </div>
            ) : (
              <div className={styles["no-content"]}>-</div>
            )}
          </div>
          <div className={styles.field}>
            <div className={styles.title}>求职意向</div>
            {user.careerObjective ? (
              <div className={styles.content}>{user.careerObjective}</div>
            ) : (
              <div className={styles["no-content"]}>-</div>
            )}
          </div>
        </section>

        <section>
          <div className={styles["section-title"]}>教育背景</div>
          {user.educationExps.map((exp, index) => (
            <div key={index}>
              <div className={styles.field}>
                <div className={styles.title}>学校名称</div>
                <div className={styles.content}>{exp.school}</div>
              </div>
              <div className={styles.field}>
                <div className={styles.title}>专业名称</div>
                <div className={styles.content}>{exp.major}</div>
              </div>
              <div className={styles.field}>
                <div className={styles.title}>学历</div>
                <div className={styles.content}>
                  {DEGREE_OPTIONS[exp.degree]}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.title}>开始时间</div>
                <div className={styles.content}>
                  {formatTime(exp.startTime)}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.title}>结束时间</div>
                <div className={styles.content}>{formatTime(exp.endTime)}</div>
              </div>
            </div>
          ))}
        </section>

        <section>
          <div className={styles["section-title"]}>工作经历</div>
          {user.workExps.map((exp, index) => (
            <div key={index}>
              <div className={styles.field}>
                <div className={styles.title}>公司名称</div>
                <div className={styles.content}>{exp.company}</div>
              </div>
              <div className={styles.field}>
                <div className={styles.title}>职位名称</div>
                <div className={styles.content}>{exp.position}</div>
              </div>
              <div className={styles.field}>
                <div className={styles.title}>部门名称</div>
                <div className={styles.content}>{exp.department}</div>
              </div>
              <div className={styles.field}>
                <div className={styles.title}>开始时间</div>
                <div className={styles.content}>
                  {formatTime(exp.startTime)}
                </div>
              </div>
              <div className={styles.field}>
                <div className={styles.title}>结束时间</div>
                <div className={styles.content}>{formatTime(exp.endTime)}</div>
              </div>
              <div className={styles.field}>
                <div className={styles.title}>描述</div>
                {exp.description ? (
                  <div className={styles.content}>{exp.description}</div>
                ) : (
                  <div className={styles["no-content"]}>-</div>
                )}
              </div>
            </div>
          ))}
        </section>

        <section>
          <div className={styles["section-title"]}>项目经验</div>
          {user.projectExps.length ? (
            <div className={styles["section-content"]}>
              {user.projectExps.map((exp, index) => (
                <div className={styles["section-content-item"]} key={index}>
                  <div className={styles.field}>
                    <div className={styles.title}>项目名称</div>
                    <div className={styles.content}>{exp.project}</div>
                  </div>
                  <div className={styles.field}>
                    <div className={styles.title}>公司名称</div>
                    <div className={styles.content}>{exp.company}</div>
                  </div>
                  <div className={styles.field}>
                    <div className={styles.title}>职责</div>
                    <div className={styles.content}>{exp.duty}</div>
                  </div>
                  <div className={styles.field}>
                    <div className={styles.title}>贡献</div>
                    <div className={styles.content}>{exp.contribution}</div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className={styles["section-no-content"]}>无</div>
          )}
        </section>

        <section>
          <div className={styles["section-title"]}>语言能力</div>
          {user.languageSkills.length ? (
            <div className={styles["section-content"]}>
              {user.languageSkills.map((skill, index) => (
                <div className={styles["section-content-item"]} key={index}>
                  <div className={styles.field}>
                    <div className={styles.title}>语言</div>
                    <div className={styles.content}>{skill.name}</div>
                  </div>
                  <div className={styles.field}>
                    <div className={styles.title}>熟练程度</div>
                    <div className={styles.content}>
                      {LANGUAGE_LEVEL_OPTIONS[skill.level]}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className={styles["section-no-content"]}>无</div>
          )}
        </section>

        <section>
          <div className={styles["section-title"]}>软件能力</div>
          {user.softwareSkills.length ? (
            <div className={styles["section-content"]}>
              {user.softwareSkills.map((skill, index) => (
                <div className={styles["section-content-item"]} key={index}>
                  <div className={styles.field}>
                    <div className={styles.title}>软件</div>
                    <div className={styles.content}>
                      {skill.type === 0
                        ? skill.name
                        : SOFTWARE_TYPE_OPTIONS[skill.type]}
                    </div>
                  </div>
                  <div className={styles.field}>
                    <div className={styles.title}>熟练程度</div>
                    <div className={styles.content}>
                      {SOFTWARE_LEVEL_OPTIONS[skill.level]}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className={styles["section-no-content"]}>无</div>
          )}
        </section>
      </div>
    </div>
  );
};

export default User;
