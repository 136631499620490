import React, { useState } from "react";
import { includesIgnoreCase } from "utils";
import styles from "./SelectTag.module.scss";

interface Props {
  allTags: Array<string>;
  value: Array<string>;
  onChange: (value: Array<string>) => void;
}

const SelectTags = ({ allTags, value, onChange }: Props) => {
  const [filter, setFilter] = useState("");

  const f = filter.trim();
  const filteredTags = f
    ? allTags.filter((t) => includesIgnoreCase(t, f))
    : allTags;

  return (
    <div className={styles["select-tag"]}>
      {allTags.length ? (
        <>
          <input
            type="search"
            placeholder="键入关键字过滤标签"
            value={filter}
            onChange={(e) => {
              setFilter(e.target.value);
            }}
          />
          <div className={styles["tag-list"]}>
            {filteredTags.length ? (
              filteredTags.map((tag) => (
                <label key={tag} className={styles["checkbox-label"]}>
                  <input
                    type="checkbox"
                    value={tag}
                    checked={value.includes(tag)}
                    onChange={() =>
                      onChange(
                        value.includes(tag)
                          ? value.filter((item) => item !== tag)
                          : [...value, tag]
                      )
                    }
                  />
                  &nbsp;{tag}
                </label>
              ))
            ) : (
              <div className={styles["text-secondary"]}>无匹配标签</div>
            )}
          </div>
        </>
      ) : (
        <div className={styles["text-secondary"]}>尚无标签</div>
      )}
    </div>
  );
};

export default SelectTags;
