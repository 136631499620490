import React from "react";
import styles from "./tag.module.scss";

interface Props {
  tags: Array<string>;
}

const TagsView = ({ tags }: Props) => {
  return (
    <div className={styles["tags-view"]}>
      {tags.map((tag) => (
        <span className={styles["tag-item"]} key={tag}>
          <span className={styles["tag-text"]}>{tag}</span>
        </span>
      ))}
    </div>
  );
};

export default TagsView;
