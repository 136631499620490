import React, { useState } from "react";
import {
  COMPANY_INDUSTRY_OPTIONS,
  COMPANY_SIZE_OPTIONS,
  COMPANY_TYPE_OPTIONS,
} from "constant";
import { backendResource } from "utils";
import LocationPicker from "components/LocationPicker";
import AddWorkModal from "./AddWorkModal";
import JobList from "./JobList";
import styles from "./EditCompany.module.scss";

interface Props {
  company: Company;
  onChange: (value: Company) => void;
  onSave: () => void;
  onDelete: () => void;
  onUploadImage: (key: "environments" | "projects", files: FileList) => void;
  onDeleteImage: (key: string, index: number) => void;
  onAddJob: () => void;
  onAddWork: (image: File, text: string) => void;
  onDeleteWork: (work: CompanyWork) => void;
}

const EditCompany = ({
  company,
  onChange,
  onSave,
  onDelete,
  onUploadImage,
  onDeleteImage,
  onAddJob,
  onAddWork,
  onDeleteWork,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedWork, setSelectedWork] = useState<CompanyWork | null>(null);

  const setPropValue = <Key extends keyof Company>(
    name: Key,
    value: Company[Key]
  ) => onChange({ ...company, [name]: value });

  return (
    <div className={styles["company-detail"]}>
      <div className={styles["top-button-row"]}>
        <button className={styles["default-button"]} onClick={onSave}>
          保存
        </button>
        <button className={styles["danger-button"]} onClick={onDelete}>
          删除
        </button>
      </div>
      <div className={styles["company-detail-body"]}>
        <div className={styles.field}>
          <div className={styles.title}>名称</div>
          <input
            type="text"
            value={company.name}
            onChange={(e) => setPropValue("name", e.target.value)}
          />
        </div>

        <div className={styles.field}>
          <LocationPicker
            value={
              company.location ?? {
                region: "CN",
                province: "0",
                city: "0",
                district: "0",
              }
            }
            onChange={(value) => setPropValue("location", value)}
          />
        </div>

        <div className={styles["content-flow"]}>
          <div className={styles.field}>
            <div className={styles.title}>行业</div>
            <select
              value={company.industry}
              onChange={(e) => setPropValue("industry", +e.target.value)}
            >
              {COMPANY_INDUSTRY_OPTIONS.map((text, index) => (
                <option key={index} value={index}>
                  {text}
                </option>
              ))}
            </select>
          </div>

          <div className={styles.field}>
            <div className={styles.title}>规模</div>
            <select
              value={company.size}
              onChange={(e) => setPropValue("size", +e.target.value)}
            >
              {COMPANY_SIZE_OPTIONS.map((text, index) => (
                <option key={index} value={index}>
                  {text}
                </option>
              ))}
            </select>
          </div>

          <div className={styles.field}>
            <div className={styles.title}>类型</div>
            <select
              value={company.type}
              onChange={(e) => setPropValue("type", +e.target.value)}
            >
              {COMPANY_TYPE_OPTIONS.map((text, index) => (
                <option key={index} value={index}>
                  {text}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className={styles["content-flow"]}>
          <div className={styles.field}>
            <div className={styles.title}>官网</div>
            <input
              type="text"
              value={company.website}
              onChange={(e) => setPropValue("website", e.target.value)}
            />
          </div>

          <div className={styles.field}>
            <div className={styles.title}>微信公众号</div>
            <input
              type="text"
              value={company.wechatAccount}
              onChange={(e) => setPropValue("wechatAccount", e.target.value)}
            />
          </div>
        </div>

        <div className={styles.field}>
          <div className={styles.title}>简介</div>
          <textarea
            value={company.description}
            onChange={(e) => setPropValue("description", e.target.value)}
          ></textarea>
        </div>

        <div className={styles.field}>
          <div className={styles.title}>公司环境</div>
          <input
            type="file"
            accept=".jpg, .jpeg, .png"
            onChange={(e) => {
              if (e.target.files) onUploadImage("environments", e.target.files);
            }}
            multiple
          />
          {company.environments?.map((img, index) => (
            <div key={index} className={styles["image-wrapper"]}>
              <img
                className={styles["field-image"]}
                src={backendResource(img)}
                alt="environment"
              />
              <div
                className={styles["image-delete-button"]}
                onClick={() => onDeleteImage("environments", index)}
              >
                点击删除
              </div>
            </div>
          ))}
        </div>

        <div className={styles.field}>
          <div className={styles.title}>最新项目</div>
          <input
            type="file"
            accept=".jpg, .jpeg, .png"
            onChange={(e) => {
              if (e.target.files) onUploadImage("projects", e.target.files);
            }}
            multiple
          />
          {company.projects?.map((img, index) => (
            <div key={index} className={styles["image-wrapper"]}>
              <img
                className={styles["field-image"]}
                src={backendResource(img)}
                alt="project"
              />
              <div
                className={styles["image-delete-button"]}
                onClick={() => onDeleteImage("projects", index)}
              >
                点击删除
              </div>
            </div>
          ))}
        </div>

        <div className={styles.field}>
          <div className={styles.title}>
            作品
            <button
              className={styles["link-button"]}
              onClick={() => {
                setSelectedWork(null);
                setIsModalOpen(true);
              }}
            >
              +&nbsp;添加作品
            </button>
          </div>
          {company.works?.map((work, index) => (
            <div key={index} className={styles["image-wrapper"]}>
              <img
                className={styles["field-image"]}
                src={backendResource(work.image)}
                alt="work"
              />
              <div className={styles["field-text"]}>{work.text}</div>
              <div className={styles["float-buttons"]}>
                <button
                  className={styles["default-button"]}
                  onClick={() => {
                    setSelectedWork(work);
                    setIsModalOpen(true);
                  }}
                >
                  修改
                </button>
                <button
                  className={styles["danger-button"]}
                  onClick={() => onDeleteWork(work)}
                >
                  删除
                </button>
              </div>
            </div>
          ))}
        </div>
        {isModalOpen && (
          <AddWorkModal
            isOpen={isModalOpen}
            work={selectedWork}
            onAdd={(image, text) => {
              setIsModalOpen(false);
              onAddWork(image, text);
            }}
            onCancel={() => setIsModalOpen(false)}
          />
        )}

        <div className={styles.field}>
          <div className={styles.title}>
            职位
            <button className={styles["link-button"]} onClick={onAddJob}>
              +&nbsp;添加职位
            </button>
          </div>

          <JobList jobs={company.jobs ?? []} />
        </div>
      </div>
    </div>
  );
};

export default EditCompany;
