import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { FaArrowUp, FaArrowDown, FaTimes, FaPlus } from "react-icons/fa";
import * as api from "api";
import { tokenState } from "state";
import NavBar from "components/NavBar";
import styles from "./index.module.scss";

const JobPriority = () => {
  const token = useRecoilValue(tokenState);
  const [jobs, setJobs] = useState<Array<Job>>([]);
  const [removedJobs, setRemovedJobs] = useState<Array<Job>>([]);
  const [jobId, setJobId] = useState("");

  const addJob = async () => {
    if (jobs.some((job) => job._id === jobId)) {
      alert("职位已经在优先级列表中!");
      setJobId("");
      return;
    }

    const { data, error } = await api.getJob(token)(jobId);
    if (data) {
      setJobs([
        {
          _id: jobId,
          priority: undefined,
          name: data.job.name,
          company: { ...data.job.company },
        },
        ...jobs,
      ]);
      setJobId("");
    }

    if (error) {
      alert(
        `添加职位优先级失败!\n错误信息:${
          typeof error === "string" ? error : "未找到职位"
        }`
      );
    }
  };

  const moveUp = (index: number) => {
    if (index > 0)
      setJobs([
        ...jobs.slice(0, index - 1),
        ...jobs.slice(index - 1, index + 1).reverse(),
        ...jobs.slice(index + 1),
      ]);
  };

  const moveDown = (index: number) => {
    if (index < jobs.length - 1)
      setJobs([
        ...jobs.slice(0, index),
        ...jobs.slice(index, index + 2).reverse(),
        ...jobs.slice(index + 2),
      ]);
  };

  const remove = (index: number) => {
    setRemovedJobs([...removedJobs, jobs[index]]);
    setJobs(jobs.filter((job, i) => i !== index));
  };

  const addBack = (index: number) => {
    setJobs([...jobs, removedJobs[index]]);
    setRemovedJobs(removedJobs.filter((job, i) => i !== index));
  };

  const save = async () => {
    let update = [
      ...jobs.map((job, index) => ({
        _id: job._id,
        priority: jobs.length - index,
      })),
      ...removedJobs.map((job) => ({ _id: job._id, priority: undefined })),
    ];

    const { data, error } = await api.updateJobPriority(token)(update);

    if (data) {
      setJobs(data.jobs);
      setRemovedJobs([]);
      alert("保存成功!");
    }

    if (error) {
      alert(`保存失败!\n错误信息: ${error}`);
    }
  };

  useEffect(() => {
    const loadJobs = async () => {
      const { data } = await api.getJobPriorities(token)();
      if (data) {
        setJobs(
          data.jobs.map((job) => ({ ...job, oldPriority: job.priority }))
        );
      }
    };

    loadJobs();
  }, [token]);

  return (
    <div className={styles.page}>
      <NavBar />
      <div className={styles["page-content"]}>
        <table>
          <thead>
            <tr>
              <th>优先级(修改前)</th>
              <th>优先级(修改后)</th>
              <th>ID</th>
              <th>职位</th>
              <th>公司</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {jobs.map((job, index) => (
              <tr key={job._id}>
                <td>{job.priority || "-"}</td>
                <td>{jobs.length - index}</td>
                <td>{job._id}</td>
                <td>{job.name}</td>
                <td>{job.company.name}</td>
                <td className={styles.buttons}>
                  <button
                    className={styles["light-button"]}
                    onClick={() => moveUp(index)}
                    disabled={index === 0}
                  >
                    <FaArrowUp />
                  </button>
                  <button
                    className={styles["light-button"]}
                    onClick={() => moveDown(index)}
                    disabled={index === jobs.length - 1}
                  >
                    <FaArrowDown />
                  </button>
                  <button
                    className={styles["light-button"]}
                    onClick={() => remove(index)}
                  >
                    <FaTimes />
                  </button>
                </td>
              </tr>
            ))}
            {removedJobs.map((job, index) => (
              <tr key={job._id}>
                <td>{job.priority}</td>
                <td>-</td>
                <td>{job._id}</td>
                <td>{job.name}</td>
                <td>{job.company.name}</td>
                <td className={styles.buttons}>
                  <button
                    className={styles["light-button"]}
                    onClick={() => addBack(index)}
                  >
                    <FaPlus />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles["action-row"]}>
          <input
            type="text"
            placeholder="职位ID"
            value={jobId}
            onChange={(e) => setJobId(e.target.value)}
          />
          <button
            className={styles["default-button"]}
            onClick={addJob}
            disabled={!jobId}
          >
            添加至优先级列表
          </button>
        </div>
        <div className={styles["action-row"]}>
          <button className={styles["primary-button"]} onClick={save}>
            保存
          </button>
        </div>
      </div>
    </div>
  );
};

export default JobPriority;
