import React, { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "lib/PrivateRoute";
import { tokenState } from "state";
import Home from "components/Home/Home";
import Login from "components/Login/Login";
import EditTag from "components/EditTag";
import JobPriority from "components/JobPriority";
import UserListPage from "components/UserListPage";
import SearchUser from "components/SearchUser/SearchUser";
import CompanyList from "components/CompanyList";
import EditJobPage from "components/EditJob";
import User from "components/User";
import "./App.scss";

function App() {
  const setTokenState = useSetRecoilState(tokenState);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) setTokenState(token);
  }, [setTokenState]);

  return (
    <Router>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <PrivateRoute path="/user-list/:jobId?">
          <UserListPage />
        </PrivateRoute>
        <PrivateRoute path="/user/:userId">
          <User />
        </PrivateRoute>
        <PrivateRoute path="/search-user">
          <SearchUser />
        </PrivateRoute>
        <PrivateRoute path="/company-list">
          <CompanyList />
        </PrivateRoute>
        <PrivateRoute path="/edit-job/:jobId">
          <EditJobPage />
        </PrivateRoute>
        <PrivateRoute path="/edit-tag">
          <EditTag />
        </PrivateRoute>
        <PrivateRoute path="/job-priority">
          <JobPriority />
        </PrivateRoute>

        <PrivateRoute path="/" exact>
          <Home />
        </PrivateRoute>
      </Switch>
    </Router>
  );
}

export default App;
