import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { FaUserTie } from "react-icons/fa";
import moment from "moment";
import {
  allTagsState,
  appErrorState,
  extraUserInfoByIdState,
  isEditingTagsState,
  newTagsByIdState,
  paginationState,
  queryStatsState,
  selectedUserIdsState,
  tagsByIdState,
  tokenState,
  usersState,
} from "state";
import * as api from "api";
import { RequestStatus } from "utils";
import { isEdited } from "utils/isEdited";
import Loading from "components/Loading";
import UserList from "components/UserList/UserList";
import BatchEditTagModal from "components/UserList/BatchEditTagModal";
import NavBar from "./NavBar";
import Footer from "./Footer";
import styles from "./index.module.scss";

const stateKey = "user-list";
const UserListPage = () => {
  const { jobId } = useParams<{ jobId: string }>();
  const token = useRecoilValue(tokenState);
  const selectedUserIds = useRecoilValue(selectedUserIdsState(stateKey));

  const setAllTags = useSetRecoilState(allTagsState(stateKey));
  const setAppError = useSetRecoilState(appErrorState(stateKey));

  const setExtraUserInfoById = useSetRecoilState(
    extraUserInfoByIdState(stateKey)
  );
  const [{ totalCount }, setQueryStats] = useRecoilState(
    queryStatsState(stateKey)
  );
  const [{ pageIndex, pageSize }, setPagination] = useRecoilState(
    paginationState(stateKey)
  );
  const [users, setUsers] = useRecoilState(usersState(stateKey));
  const [tagsById, setTagsById] = useRecoilState(tagsByIdState(stateKey));
  const [newTagsById, setNewTagsById] = useRecoilState(
    newTagsByIdState(stateKey)
  );
  const [isEditingTags, setIsEditingTags] = useRecoilState(
    isEditingTagsState(stateKey)
  );

  const [isBatchEditTagModalOpen, setIsBatchEditTagModalOpen] = useState(false);
  const [searchStatus, setSearchStatus] = useState(RequestStatus.Default);

  const loadPage = async (
    jobId: string,
    pageIndex: number,
    pageSize: number,
    name: string
  ) => {
    setSearchStatus(RequestStatus.Pending);
    const { data, error } = await api.getUsers(token)(
      jobId,
      pageIndex,
      pageSize,
      name
    );

    if (data) {
      const list = data.users;
      setUsers(list);
      let byId: Record<string, ExtraUserInfo> = {};

      list.forEach((user) => {
        let extra: ExtraUserInfo = {};
        if (user.educationExps) {
          extra.degree = Math.max(
            ...user.educationExps.map((exp) => exp.degree)
          );
        }
        if (user.workExps) {
          // lengthOfService
          extra.workStartYear = Math.min(
            ...user.workExps.map((exp) => moment(exp.startTime).valueOf())
          );

          // current position and company
          let currentWorkExp = user.workExps.find(
            (exp) => exp.endTime === null
          );
          if (currentWorkExp) {
            extra.currentPosition = currentWorkExp.position;
            extra.currentCompany = currentWorkExp.company;
          }
        }
        byId[user._id] = extra;
      });

      setExtraUserInfoById(byId);
      setSearchStatus(RequestStatus.Success);
    }

    if (error) {
      setSearchStatus(RequestStatus.Failed);
      setAppError(error);

      console.log(error);
    }
  };

  const loadUsers = async (
    jobId: string,
    pageIndex: number,
    pageSize: number,
    name: string
  ) => {
    const startTime = Date.now();
    setSearchStatus(RequestStatus.Pending);

    const { data, error } = await api.countUsers(token)({
      jobId,
      name,
    });

    if (data) {
      await loadPage(jobId, pageIndex, pageSize, name);

      setQueryStats({
        totalCount: data.count,
        timeElapsed: (Date.now() - startTime) / 1000,
      });
    }

    if (error) {
      setSearchStatus(RequestStatus.Failed);

      console.log(error);
      setAppError(error);
    }
  };

  const search = async (name?: string) => {
    setPagination({ pageSize, pageIndex: 0 });

    await loadUsers(jobId, 0, pageSize, name ?? "");
  };

  const onPageChange = async (pageIndex: number, pageSize: number) => {
    setPagination({ pageIndex, pageSize });

    await loadUsers(jobId, pageIndex, pageSize, "");
  };

  const startEditingTag = () => {
    let byId: Record<string, Array<string>> = {};
    users.forEach((u) => {
      byId[u._id] = u.tags ? [...u.tags] : [];
    });
    setTagsById({ ...byId });
    setNewTagsById({ ...byId });
    setIsEditingTags(true);
  };

  const saveChangedTags = async () => {
    const { data, error } = await api.updateTags(token)(getEditedUsers());

    if (data) {
      setTagsById({});
      setNewTagsById({});
      setIsEditingTags(false);
      await loadAllTags();
    }

    if (error) {
      setAppError(error);
    }
  };

  const cancelTagsChange = () => {
    setTagsById({});
    setNewTagsById({});
    setIsEditingTags(false);
  };

  const getEditedUsers = () => {
    let edited: Array<{ _id: string; tags: Array<string> }> = [];
    Object.keys(newTagsById).forEach((_id) => {
      if (isEdited({ tags: tagsById[_id], newTags: newTagsById[_id] })) {
        edited = [...edited, { _id, tags: newTagsById[_id] }];
      }
    });

    console.log({ edited, tagsById, newTagsById });

    return edited;
  };

  const loadAllTags = useCallback(async () => {
    const { data, error } = await api.getTags(token)();

    if (data) {
      setAllTags(data.tags);
    }
    if (error) {
      console.log(error);
    }
  }, [setAllTags, token]);

  useEffect(() => {
    loadAllTags();
  }, [loadAllTags]);

  useEffect(() => {
    loadUsers(jobId, pageIndex, pageSize, "");
  }, []);

  return (
    <div className={styles["users-page"]}>
      <NavBar
        isEditingTags={isEditingTags}
        editDisabled={!users.length}
        batchEditDisabled={!selectedUserIds.length}
        saveDisabled={!getEditedUsers().length}
        onSearch={search}
        onEdit={startEditingTag}
        onSave={saveChangedTags}
        onCancel={cancelTagsChange}
        onBatchEdit={() => {
          setIsBatchEditTagModalOpen(true);
        }}
      />

      <div className={styles["page-content"]}>
        {searchStatus === RequestStatus.Pending ? (
          <Loading />
        ) : totalCount === 0 ? (
          <div className={styles["no-user"]}>
            <FaUserTie />
            <p>尚无申请人</p>
          </div>
        ) : (
          <UserList stateKey={stateKey} />
        )}
      </div>
      {!isEditingTags && <Footer onPageChange={onPageChange} />}
      {isBatchEditTagModalOpen && (
        <BatchEditTagModal
          stateKey={stateKey}
          isOpen={isBatchEditTagModalOpen}
          userIds={selectedUserIds}
          onClose={() => setIsBatchEditTagModalOpen(false)}
        />
      )}
    </div>
  );
};

export default UserListPage;
