import React, { useMemo, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { FaTimes } from "react-icons/fa";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { newTagsByIdState } from "state";
import { equalsIgnoreCase } from "utils";
import styles from "./BatchEditTagModal.module.scss";

interface Props {
  stateKey: string;
  isOpen: boolean;
  userIds: string[];
  onClose: () => void;
}

const BatchEditTagModal = ({ stateKey, isOpen, userIds, onClose }: Props) => {
  const [newTagsById, setNewTagsById] = useRecoilState(
    newTagsByIdState(stateKey)
  );
  const [text, setText] = useState("");
  const isComposingRef = useRef(false);

  const entries: Record<string, number> = useMemo(() => {
    let byId: Record<string, number> = {};

    userIds.forEach((_id) => {
      const newTags = newTagsById[_id];
      newTags.forEach((t) => {
        if (!byId[t]) {
          byId[t] = 0;
        }

        byId[t]++;
      });
    });

    return byId;
  }, [newTagsById, userIds]);

  const addTag = () => {
    const newTag = text.trim();
    if (newTag) {
      let byId = { ...newTagsById };
      userIds.forEach((_id) => {
        const tagExist = byId[_id].some((t) => equalsIgnoreCase(t, newTag));
        if (!tagExist) {
          byId[_id] = [...byId[_id], newTag];
        }
      });

      setNewTagsById(byId);
    }

    setText("");
  };

  const removeTag = (tag: string) => {
    let byId = { ...newTagsById };

    userIds.forEach((_id) => {
      byId[_id] = byId[_id].filter((t) => !equalsIgnoreCase(t, tag));
    });

    setNewTagsById(byId);
  };

  return (
    <Modal className={styles.modal} isOpen={isOpen}>
      <ModalHeader>批量编辑标签</ModalHeader>
      <ModalBody>
        <p>用户: {userIds.length}</p>
        {Object.keys(entries).length ? (
          <p>
            {Object.entries(entries).map(([tag, cnt]) => (
              <span className={styles["tag-item"]} key={tag}>
                <span className={styles["tag-text"]}>
                  {tag}
                  <span className={styles["tag-count"]}>{cnt}</span>
                </span>
                <button
                  className={styles["light-button"]}
                  onClick={() => removeTag(tag)}
                >
                  <FaTimes />
                </button>
              </span>
            ))}
          </p>
        ) : (
          <p className={styles["text-secondary"]}>所选用户没有标签可删除.</p>
        )}
        <div className={styles.field}>
          <div className={styles.title}>添加标签 (输入空格以添加)</div>
          <input
            type="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            onKeyDown={(e) => {
              if (isComposingRef.current) {
                return;
              }

              if (e.code === "Space") {
                addTag();
                e.preventDefault();
              }
            }}
            onCompositionStart={() => {
              isComposingRef.current = true;
            }}
            onCompositionEnd={() => {
              isComposingRef.current = false;
            }}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <button className={styles["primary-button"]} onClick={onClose}>
          完成
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default BatchEditTagModal;
