import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { backendResource } from "utils";
import styles from "./AddWorkModal.module.scss";

interface Props {
  isOpen: boolean;
  work: CompanyWork | null;
  onAdd: (image: File, text: string) => void;
  onCancel: () => void;
}

const AddWorkModal = ({ isOpen, work, onAdd, onCancel }: Props) => {
  const [file, setFile] = useState<File>();
  const [text, setText] = useState(work?.text ?? "");
  const [imageSrc, setImageSrc] = useState<string>(
    work?.image ? backendResource(work.image) : ""
  );

  const changeFile = (files: FileList | null) => {
    if (!files?.length) return;

    const reader = new FileReader();
    reader.onloadend = (e) => {
      setImageSrc(e.target?.result?.toString() ?? "");
      setFile(files[0]);
    };
    reader.readAsDataURL(files[0]);
  };

  return (
    <Modal className={styles["add-work-modal"]} isOpen={isOpen} size="lg">
      <ModalHeader>添加作品</ModalHeader>
      <ModalBody>
        <div className={styles["image-selector"]}>
          <input
            type="file"
            accept=".jpg, .jpeg, .png"
            onChange={(e) => changeFile(e.target.files)}
          />
          {imageSrc && <img src={imageSrc} alt="preview" />}
          <div className={styles.cover}></div>
        </div>
        <div className={styles.field}>
          <div className={styles.title}>描述</div>
          <textarea value={text} onChange={(e) => setText(e.target.value)} />
        </div>
      </ModalBody>

      <ModalFooter className={styles.footer}>
        <button
          className={styles["primary-button"]}
          onClick={() => {
            if (file) onAdd(file, text);
          }}
        >
          添加
        </button>
        <button className={styles["default-button"]} onClick={onCancel}>
          取消
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default AddWorkModal;
