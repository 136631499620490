import React from "react";
import { useRecoilValue } from "recoil";
import { FaUserTie } from "react-icons/fa";
import { appErrorState, queryStatsState, usersState } from "state";
import { RequestStatus } from "utils";
import Loading from "components/Loading";
import UserList from "components/UserList/UserList";
import styles from "./SearchResult.module.scss";

interface Props {
  searchStatus: number;
}

const stateKey = "search-user";

const SearchResult = ({ searchStatus }: Props) => {
  const users = useRecoilValue(usersState(stateKey));
  const { totalCount, timeElapsed } = useRecoilValue(queryStatsState(stateKey));
  const appError = useRecoilValue(appErrorState(stateKey));

  return (
    <div className={styles["search-result-panel"]}>
      {searchStatus === RequestStatus.Default ? (
        <div className={styles["message-container"]}>
          <div className={styles.message}>点击"查找"</div>
        </div>
      ) : searchStatus === RequestStatus.Pending ? (
        <Loading />
      ) : searchStatus === RequestStatus.Failed ? (
        <div className={styles["message-container"]}>
          <div>
            <div>查找失败</div>
            <div>{appError}</div>
          </div>
        </div>
      ) : searchStatus === RequestStatus.Success ? (
        users.length === 0 ? (
          <div className={styles["message-container"]}>
            <div className={styles.message}>
              <p>
                <FaUserTie size="2em" />
              </p>
              <p>无匹配结果</p>
            </div>
          </div>
        ) : (
          <div className={styles.result}>
            <div className={styles.content}>
              <UserList stateKey={stateKey} />
            </div>
            <footer className={styles["page-footer"]}>
              <span className={styles["flex-grow"]}>共{totalCount}名用户</span>
              <span>耗时{timeElapsed.toFixed(2)}秒</span>
            </footer>
          </div>
        )
      ) : null}
    </div>
  );
};

export default SearchResult;
