import React from "react";
import {
  FaAngleDoubleLeft,
  FaChevronLeft,
  FaChevronRight,
  FaAngleDoubleRight,
} from "react-icons/fa";
import { useRecoilValue } from "recoil";
import { paginationState, queryStatsState } from "state";
import styles from "./Footer.module.scss";

interface Props {
  onPageChange: (index: number, pageSize: number) => void;
}

const stateKey = "user-list";
const Footer = ({ onPageChange }: Props) => {
  const { pageSize, pageIndex } = useRecoilValue(paginationState(stateKey));
  const { totalCount, timeElapsed } = useRecoilValue(queryStatsState(stateKey));

  const pageCount = Math.ceil(totalCount / pageSize);
  return (
    <footer className={styles["page-footer"]}>
      <div className={styles.stats}>
        共{totalCount}名用户, 耗时{timeElapsed}秒
      </div>
      <div className={styles.pagination}>
        <div className={styles.group}>
          <button
            className={styles["icon-only-button"]}
            onClick={() => onPageChange(0, pageSize)}
            disabled={pageCount === 0 || pageIndex === 0}
          >
            <FaAngleDoubleLeft />
          </button>
          <button
            className={styles["icon-only-button"]}
            onClick={() => {
              if (pageIndex > 0) onPageChange(pageIndex - 1, pageSize);
            }}
            disabled={pageCount === 0 || pageIndex === 0}
          >
            <FaChevronLeft />
          </button>
          <div>
            {pageIndex + 1} / {pageCount}
          </div>
          <button
            className={styles["icon-only-button"]}
            onClick={() => {
              if (pageIndex < pageCount - 1)
                onPageChange(pageIndex + 1, pageSize);
            }}
            disabled={pageCount === 0 || pageIndex === pageCount - 1}
          >
            <FaChevronRight />
          </button>
          <button
            className={styles["icon-only-button"]}
            onClick={() => onPageChange(pageCount - 1, pageSize)}
            disabled={pageCount === 0 || pageIndex === pageCount - 1}
          >
            <FaAngleDoubleRight />
          </button>
        </div>
        <div className={styles.group}>
          <select
            value={pageSize}
            onChange={(e) => onPageChange(0, +e.target.value)}
          >
            {[10, 25, 50, 100].map((value) => (
              <option key={value}>{value}</option>
            ))}
          </select>
          项 / 页
        </div>
      </div>
    </footer>
  );
};

export default Footer;
